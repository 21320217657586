<template>
  <div id="app">
    <div
      v-if="otolierData"
      class="top">
      <header>
        <img src="https://eys-file.s3-ap-northeast-1.amazonaws.com/community/dummy/image/top/header@2x.png" alt="ヘッダー">
      </header>
      <div class="hello">
        <div class="top--search-box">
          <search-box/>
        </div>
        <img src="https://eys-file.s3-ap-northeast-1.amazonaws.com/community/dummy/image/top/HELLO.svg" alt="">
      </div>
      <div class="head-image">
        <img :src="otolierData.top_space.head.icon" alt="">
        <label>{{ otolierData.top_space.head.status }}</label>
      </div>
      <official-member
        v-for="(m, i) in officialMembers"
        :key="m.name"
        :member="m"
        :id="`official-member-${i}`"
        @action-button-click="showMaestroModal"/>
      <div class="center-info">
        <img src="https://eys-file.s3-ap-northeast-1.amazonaws.com/community/dummy/image/top/center_logo.svg"
             alt="Your 2nd Community">
        <div class="members-abstract">
          <span class="member-count">16</span>人のMember、<span class="member-count">7</span>人のInstructorがいます
        </div>
        <div class="center-info--buttons">
          <button class="center-info--button">
            友達一覧
            <span class="center-info--button-badge friends">16</span>
          </button>
          <button class="center-info--button">
            所有チケット一覧
            <span class="center-info--button-badge tickets">16</span>
          </button>
        </div>
        <user-relation-image class="center-panel-image"/>
      </div>
      <img src="https://eys-file.s3-ap-northeast-1.amazonaws.com/community/dummy/image/top/chat-support%402x.png"
           alt="チャットサポート"
           class="chat-support">
      <a @click="gotoHome" class="return-to-top">
        <img src="https://eys-file.s3-ap-northeast-1.amazonaws.com/community/dummy/image/otolier/return-to-top@2x.png"
             alt="2nd Community TOP">
      </a>
    </div>
    <div class="contents">
      <div class="tags">
        <div class="tag-group">
          <span class="tag-group--title">コミュニティ</span>
          <tag-label label="すべて" selected/>
          <tag-label label="自分宛"/>
          <tag-label label="所属教室"/>
          <tag-label label="所属バンド"/>
        </div>
        <div class="tag-group">
          <span class="tag-group--title">関連</span>
          <tag-label label="すべて" selected/>
          <tag-label label="友達"/>
          <tag-label label="講師"/>
          <tag-label label="スタッフ"/>
          <tag-label label="Otolier"/>
          <tag-label label="知り合い"/>
        </div>
      </div>
      <div
        v-if="timelineData"
        class="timeline">
        <div class="timeline--card-container">
          <timeline-card
            v-for="(p, i) in timeline1"
            :key="i"
            :post="p"
            class="timeline--card"/>
        </div>
        <div class="timeline--card-container">
          <timeline-card
            v-for="(p, i) in timeline2"
            :key="i"
            :post="p"
            class="timeline--card"/>
        </div>
      </div>
    </div>
    <maestro-modal
      v-if="maestroModalVisible && otolierData"
      :maestro="otolierData.details.fernando"
      class="maestro-popup"
      @close-button-click="closeMaestroModal"/>
  </div>
</template>

<script>
import { api } from '../api';

const MaestroModal = () => import('../components/MaestroModal');
const OfficialMember = () => import('../components/OfficialMember');
const SearchBox = () => import('../components/SearchBox');
const TagLabel = () => import('../components/TagLabel');
const TimelineCard = () => import('../components/TimelineCard');
const UserRelationImage = () => import('../components/UserRelationImageMock');

export default {
  name: 'OtolierMock',
  components: {
    MaestroModal,
    OfficialMember,
    SearchBox,
    TagLabel,
    TimelineCard,
    UserRelationImage
  },
  data() {
    return {
      timelineData: null,
      otolierData: null,
      maestroModalVisible: false
    }
  },
  created() {
    api.get('/member_api/v1/dummy/top/timeline').then(res => {
      this.timelineData = res.data;
    });
    api.get('/member_api/v1/dummy/otolier/otolier').then(res => {
      this.otolierData = res.data;
    });
  },
  computed: {
    officialMembers() {
      if (this.otolierData == null) {
        return [];
      }
      return this.otolierData.top_space.relations;
    },
    timeline1() {
      if (this.timelineData == null) {
        return [];
      }
      const v = this.timelineData.timeline;
      return [v[0], v[2], v[4]];
      // return this.timelineData.timeline.filter((t, i) => i % 2 === 0);
    },
    timeline2() {
      if (this.timelineData == null) {
        return [];
      }
      const v = this.timelineData.timeline;
      return [v[1], v[3], v[5], v[6]];
      // return this.timelineData.timeline.filter((t, i) => i % 2 === 1);
    }
  },
  methods: {
    showMaestroModal() {
      this.maestroModalVisible = true;
    },
    closeMaestroModal() {
      this.maestroModalVisible = false;
    },
    gotoHome() {
      window.location.href = '/';
    }
  }
}
</script>

<style scoped>
#app {
  position: relative;
  background-color: #eeeeee;
}

.top {
  width: 100%;
  height: 1080px;
  padding-top: 14px;
  padding-bottom: 20px;
  position: relative;
  background-color: transparent;
  margin: 0 auto;
}

header img {
  width: 1317px;
  height: 40px;
  display: block;
  margin: 14px auto 0 auto;
}

.hello {
  height: 108px;
  width: 800px;
  margin: 32px auto 0 auto;
  position: relative;
}

.hello img {
  height: 108px;
  width: 800px;
}

.top--search-box {
  position: absolute;
  left: 250px;
  top: 29px;
}

.head-image {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 229px;
  height: 159px;
}

.head-image img {
  display: block;
  margin: 0 auto;
}

.head-image label {
  font-size: 12px;
  font-weight: 600;
  color: #222222;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  height: 40px;
  padding: 0 13px;
  position: absolute;
  top: 135px;
  left: 44px;
}

.center-info {
  text-align: center;
  margin-top: 442px;
}

.members-abstract {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-align: center;
  color: #222222;
}

.center-panel-image {
  width: 836px;
  height: 150px;
  display: block;
  margin: 10px auto 0 auto;
}

.member-count {
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: normal;
  color: #7799dd;
}

.center-info--buttons {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-info--button {
  width: 120px;
  height: 30px;
  border-radius: 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: none;
  font-size: 12px;
  font-weight: 600;
  color: #222222;
  position: relative;
  margin: 0 10px;
}

.center-info--button-badge {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-info--button-badge.friends {
  background-image: linear-gradient(to right, #77ddbb, #33cc88 103%);
}

.center-info--button-badge.tickets {
  background-image: linear-gradient(to right, #ff8899, #ff6677);
}

.chat-support {
  position: absolute;
  display: block;
  bottom: 30px;
  right: 30px;
  width: 180px;
  height: 180px;
}

#official-member-0 {
  position: absolute;
  top: 386px;
  left: 323px;
}

#official-member-1 {
  position: absolute;
  top: 498px;
  left: 711px;
}

#official-member-2 {
  position: absolute;
  top: 386px;
  left: 1099px;
}

#official-member-3 {
  position: absolute;
  top: 505px;
  left: 1361px;
}

.contents {
  width: 1000px;
  background-color: #eeeeee;
  margin: 20px auto 0 auto;
}

.tags {
  width: 100%;
  height: 100px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}

.tag-group {
  display: flex;
  align-items: center;
}

.tag-group--title {
  font-size: 12px;
  color: #222222;
}

.timeline {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -15px 0 -15px;
}

.timeline--card-container {
  flex-basis: 50%;
  padding: 0 15px;
}

.timeline--card-container .timeline--card:not(:first-child) {
  margin-top: 30px;
}

.return-to-top {
  position: absolute;
  top: 100px;
  left: 0;
}

.return-to-top img {
  width: 280px;
  height: 80px;
}
</style>

<style>
body {
  font-family: HiraginoSans, Meiryo, Arial, sans-serif;
  font-size: 16px;
}
</style>
