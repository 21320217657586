<template>
  <div class="member-confirm-unfollow-container">
      <modal-background
      class="modal-background"/>
      <div class="member-confirm-unfollow-popup">
          <p>フォローを解除します。解除した方とのチャットが見れなくなります。よろしいですか？</p>
        <div class="member-confirm-unfollow-action">
            <button type="button" 
                    class="member-confirm-unfollow-action-cancel"
                    @click="cancelUnfollow">
                キャンセル
            </button>
            <button type="submit" 
                    class="member-confirm-unfollow-action-create"
                    @click="acceptUnfollow">
                はい
            </button>
        </div>
       </div>
  </div>
</template>
<script>

import ModalBackground from './ModalBackground.vue';

export default {
  name: 'confirmUnfollow',
  components: {
    ModalBackground
  },
  props: {
  },
  methods: {
     acceptUnfollow() {
        this.$emit('acceptUnfollowConfirm');
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
    },
     cancelUnfollow() {
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
    }
  }
}
</script>
<style scoped>
.member-confirm-unfollow-container{
  position: fixed;
  right: calc(50% - 160px);
  top: 22%;
  z-index: 102;
}
.member-confirm-unfollow-popup{
  height: 170px;
  width: 320px;
  background-color: #FFFFFF;
  border-radius: 30px;
  position: relative;
  z-index: 100;
}
.member-confirm-unfollow-popup p {
  font-size: 14px;
  font-weight: 600;
  padding: 30px 30px 0 30px;
  text-align: center;
}
.member-confirm-unfollow-member{
  position: absolute;
  top: 14%;
  left: 16%;
}
.member-confirm-unfollow-action{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  left: 13%;
}
.member-confirm-unfollow-action-create{
  display: block;
  width: 40%;
  margin-bottom: 10px;
  height: 40px;
  background-color: #FF8899;
  border: 2px solid #FFFFFF;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
  color: #FFFFFF;
  font-size: 13px;
  border-radius: 40px;
  cursor: pointer;
}
.member-confirm-unfollow-action-cancel{
  display: block;
  width: 40%;
  height: 40px;
  background-color: #F0F0F0;
  border: 2px solid #FFFFFF;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
  font-size: 13px;
  border-radius: 40px;
  cursor: pointer;
}
</style>