<template>
  <div class="modal-background"/>
</template>

<script>
export default {
  name: 'ModalBackground'
}
</script>

<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  opacity: 0.5;
  background-color: #000000;
  z-index: 99;
}
</style>
