import Vue from "vue";
import Vuex from "vuex";
import { api } from "../api";
import AudioVisual from "vue-audio-visual";
import { apiArt } from "../api_art";
Vue.use(Vuex);
Vue.use(AudioVisual);

const store = new Vuex.Store({
  state: {
    currentUser: null,
    followings: [],
    followingsSliced: [],
    activeMember: null,
    activeChatRoom: null,
    minimizedChatRooms: [],
    chatOptions: {},
    supporters: [],
    role: null,
    master: null,
    members: [],
    friendRequest: [],
    createCommunity: null,
    groups: [],
    createFriendRequest: null,
    communities: [],
    isCommunity: false,
    community: null,
    jingleSounds: null,
    singleJingleSound: null,
    jingleGenres: null,
    jingleBalloons: null,
    singleJingleBalloon: null,
    jingleMessage: null,
    jingle: null,
    jingleOriginalSound: null,
    screenWidth: null,
    JinglesPerUser: null,
    updatedJingle: null,
    deleteJingleData: null,
    jingleModalVisible: false,
    EditJinglePass: false,
    jingleStep: 1,
    jingleId: null,
    myOwnedRooms: []
  },

  getters: {
    memberCount(state) {
      return state.followings.filter(f => f.followee && !f.followee?.teacher).length;
    },

    artistCount(state) {
      return state.followings.filter(f => f.followee?.teacher).length;
    },

    communityCount(state) {
      return state.followings.filter(f => f.community).length;
    },

    latestTimestamp(state) {
      if (!state.activeChatRoom) {
        return null;
      }
      const times = state.activeChatRoom.messages.map(e => new Date(e.updated_at)).sort((a, b) => b - a);
      return times[0] ? times[0].toISOString() : null;
    },

    relationalMembers(state) {
      if (state.followings.length === 0) {
        return [];
      }
      const map = {};
      state.followings.filter(f => !!f.followee).forEach(f => {
        if (map[f.followee.relationship] == null) {
          map[f.followee.relationship] = [];
        }
        map[f.followee.relationship].push(f.followee);
      });
      let maxRel = null;
      let maxSize = 0;
      for (let rel in map) {
        if (map[rel].length > maxSize) {
          maxSize = map[rel].length;
          maxRel = rel;
        }
      }
      if (maxRel == null) {
        return [];
      }
      return state.followings
        .filter(f => f.followee && f.followee.relationship === maxRel)
        .map(f => f.followee);
    },

    showChatbot(state) {
      return state.chatOptions.showChatbot;
    },

    videoDurationLimit(state) {
      return state.chatOptions.videoLimit;
    },

    isStaff(state) {
      if (state.role === "staff") {
        return true;
      }
      return false;
    },
    checkJingleModalVisible(state) {
      const jingleModal = state.jingleModalVisible
      if (jingleModal == false) {
        state.singleJingleBalloon = null;
        state.singleJingleSound = null;
        state.jingleMessage = null;
        state.jingleStep = 1;
        state.EditJinglePass = false;
        state.jingleId = null
      }
    }
  },

  actions: {
    fetchCurrentUser(context) {
      return api.get("/member_api/v1/me").then(result => {
        context.commit("setCurrentUser", result.data.me);
      });
    },

    fetchFollowingMembers(context) {
      const followingMemberRequest = api.get("/member_api/v1/followings");
      const communitiesRequest = apiArt.get(`${API_URL}/communities/mine`);
      return Promise.all([communitiesRequest, followingMemberRequest]).then(function (result) {
        context.commit("setCommunities", result[0].data);
        context.commit("setFollowingMembers", result[1].data.followings);
      });
    },

    fetchActiveMember(context) {
      if (!context.state.activeMember) {
        return Promise.resolve();
      }
      return api
        .get(
          `/member_api/v1/members/${context.state.activeMember.id}/follow_info`
        )
        .then(result => {
          const member = {
            ...context.state.activeMember,
            ...result.data.member
          };
          context.commit("upsertFollowingMember", member);
          context.commit("setActiveMember", member);
        });
    },

    followActiveMember(context) {
      if (!context.state.activeMember) {
        return Promise.resolve();
      }
      return api
        .post("/member_api/v1/followings", {
          member_id: context.state.activeMember.id
        })
        .then(result => {
          const member = {
            ...context.state.activeMember,
            ...result.data.following.followee,
            following: true,
            followedCount: context.state.activeMember.followedCount + 1
          };
          context.commit("upsertFollowingMember", member);
          context.commit("setActiveMember", member);
        });
    },

    unfollowActiveMember(context) {
      if (!context.state.activeMember) {
        return Promise.resolve();
      }
      return api
        .delete(`/member_api/v1/followings/${context.state.activeMember.id}`)
        .then(_ => {
          context.commit("removeFollowingMember", context.state.activeMember);
          context.commit("setActiveMember", {
            ...context.state.activeMember,
            following: false,
            followedCount: context.state.activeMember.followedCount - 1
          });
        });
    },

    startChatWithActiveArtMember(context) {
      if (!context.state.activeMember) {
        return Promise.resolve();
      }
      return api
        .post("/member_api/v1/chat_rooms", {
          member_id: context.state.activeMember.member.sc_id
        })
        .then(result => {
          context.commit("setActiveChatRoom", result.data.chatRoom);
        })
        .catch(error => {
          alert(error.response.data.error || "予期せぬエラーが発生しました");
        });
    },

    startChatWithActiveMember(context) {
      if (!context.state.activeMember) {
        return Promise.resolve();
      }
      return api
        .post("/member_api/v1/chat_rooms", {
          member_id: context.state.activeMember.id
        })
        .then(result => {
          context.commit("setActiveChatRoom", result.data.chatRoom);
        })
        .catch(error => {
          alert(error.response.data.error || "予期せぬエラーが発生しました");
        });
    },

    startChatWithCustomerSupport(context) {
      return api
        .post("/member_api/v1/chat_rooms/create_customer_support")
        .then(result => {
          context.commit("setActiveChatRoom", result.data.chatRoom);
        })
        .catch(error => {
          alert(error.response.data.error || "予期せぬエラーが発生しました");
        });
    },

    loadNewMessages(context) {
      if (!context.state.activeChatRoom) {
        return Promise.resolve();
      }
      if (context.state.activeChatRoom.image) {
        return apiArt
          .get(
            `${API_URL}/chat_rooms/${context.state.activeChatRoom.id}/messages`,
            {
              params: {
                timestamp: context.getters.latestTimestamp
              }
            }
          )
          .then(result => {
            context.commit("updateMessages", result.data);
            return result;
          });
      } else {
        return api
          .get(
            `/member_api/v1/chat_rooms/${context.state.activeChatRoom.id}/messages`,
            {
              params: {
                timestamp: context.getters.latestTimestamp
              }
            }
          )
          .then(result => {
            context.commit("updateMessages", result.data.messages);
            return result;
          });
      }
    },

    sendMessage(context, data) {
      if (!context.state.activeChatRoom) {
        return Promise.resolve();
      }

      return api
        .post(
          `/member_api/v1/chat_rooms/${context.state.activeChatRoom.id}/messages`,
          data
        )
        .then(result => {
          context.commit("updateMessages", result.data.messages);
        });
    },

    sendReaction(context, data) {
      if (!context.state.activeChatRoom) {
        return Promise.resolve();
      }

      return api
        .post(
          `/member_api/v1/chat_rooms/${context.state.activeChatRoom.id}/messages/${data.msgId}/create_reaction`,
          { icon: data.reaction.icon, package: data.reaction.package }
        ).then(({ data }) => {
          const msg = data.chat_message;
          context.commit('updateReactions', msg.id, msg.reactions);
        });
    },

    loadChatRooms(context, room_ids) {
      return apiArt
        .get(`${API_URL}/chat_rooms`, {
          params: { room_ids }
        })
        .then(result => {
          context.commit("setMinimizedChatRooms", result.data);
          return result;
        });
    },

    loadChatOptions(context) {
      return api.get("/member_api/v1/chat_config").then(result => {
        context.commit("setChatOptions", result.data);
      });
    },

    fetchCustomerSupportMembers(context) {
      return api.get("/member_api/v1/customer_supporters").then(result => {
        context.commit("setCustomerSupportMembers", result.data.supporters);
      });
    },

    getRole(context) {
      return api.get("/member_api/v1/me/role").then(result => {
        context.commit("setRole", result.data.role);
      });
    },

    getMaster(context) {
      return apiArt.get(`${API_URL}/master`).then(result => {
        context.commit("setMaster", result.data);
      });
    },

    createCommunity(context, formData) {
      return apiArt
        .post(`${API_URL}/communities`, formData)
        .then(result => {
          context.commit("setCreateCommunity", result.data);
          return result;
        });
    },

    createFriendRequest(context, formData) {
      return apiArt
        .post(`${API_URL}/friend_requests`, formData)
        .then(result => {
          context.commit("setCreateFriendRequest", result.data);
        });
    },

    loadCommunityChat(context, id) {
      return apiArt
        .get(`${API_URL}/chat_rooms/` + id)
        .then(result => {
          context.commit("setActiveChatRoom", result.data);
          return result;
        });
    },

    searchMembers(context, searchMemberParams) {
      const user_type_hash = {
        "all": 0,
        "member": 1,
        "teacher": 2,
        "staff": 3
      };
      var searchParams = { only_friend: searchMemberParams.only_friend };

      if (searchMemberParams.page_number > -1) {
        searchParams.page_number = searchMemberParams.page_number;
      }
      if (searchMemberParams.page_items > 0) {
        searchParams.page_items = searchMemberParams.page_items;
      }
      if (searchMemberParams.keyword != '') {
        searchParams.keyword = searchMemberParams.keyword;
      }
      if (searchMemberParams.user_type != '' && searchMemberParams.user_type != 'all') {
        searchParams.user_type = user_type_hash[searchMemberParams.user_type];
      }
      if (searchMemberParams.user_type != "staff") {
        if (searchMemberParams.course_id != []) {
          searchParams.course_ids = searchMemberParams.course_id;
        }
        if (searchMemberParams.studio_ids != []) {
          searchParams.studio_ids = searchMemberParams.studio_ids;
        }
        if (searchMemberParams.ranks != []) {
          searchParams.ranks = searchMemberParams.ranks;
        }
        if (searchMemberParams.areas != []) {
          searchParams.area_ids = searchMemberParams.areas;
        }
      }
      if (searchMemberParams.admission_date_from != [] && searchMemberParams.admission_date_from.length > 0 && searchMemberParams.admission_date_from[0].join('-') != '') {
        searchParams.admission_date_from = searchMemberParams.admission_date_from[0].join('-');
      }
      if (searchMemberParams.admission_date_to != [] && searchMemberParams.admission_date_to.length > 0 && searchMemberParams.admission_date_to[0].join('-') != '') {
        searchParams.admission_date_to = searchMemberParams.admission_date_to[0].join('-');
      }
      // user status. 0: active, 1: before active, 2: 休会, not set: active

      // active: 活動中
      // pre_entry: 開始前
      // breaking: 休会

      const status_hash = {
        "active": 0,
        "pre_entry": 1,
        "breaking": 2
      };
      if (searchMemberParams.status != []) {
        var statuses = searchMemberParams.status.map(st => { return status_hash[st] });
        searchParams.statuses = statuses;
      }

      if (searchMemberParams.excluded_ids != []) {
        searchParams.excluded_ids = searchMemberParams.excluded_ids;
      }

      return apiArt
        .get(searchMemberParams.is_staff_role ? `${API_URL}/members/staffs` : `${API_URL}/members/friends`, {
          params: searchParams
        })
        .then(result => {
          context.commit("setMembers", result.data.members);
          return result;
        });
    },

    searchGroups(context, searchMemberParams) {
      const user_type_hash = {
        "all": 0,
        "member": 1,
        "teacher": 2,
        "staff": 3
      };

      var searchParams = { only_mine: searchMemberParams.only_friend };

      if (searchMemberParams.page_number > -1) {
        searchParams.page_number = searchMemberParams.page_number;
      }
      searchParams.page_items = 6;
      if (searchMemberParams.keyword != '') {
        searchParams.keyword = searchMemberParams.keyword;
      }
      if (searchMemberParams.user_type != '' && searchMemberParams.user_type != 'all') {
        searchParams.user_type = user_type_hash[searchMemberParams.user_type];
      }
      if (searchMemberParams.user_type != "staff") {
        if (searchMemberParams.course_id != []) {
          searchParams.course_ids = searchMemberParams.course_id;
        }
        if (searchMemberParams.studio_ids != []) {
          searchParams.studio_ids = searchMemberParams.studio_ids;
        }
        if (searchMemberParams.ranks != []) {
          searchParams.ranks = searchMemberParams.ranks;
        }
        if (searchMemberParams.areas != []) {
          searchParams.area_ids = searchMemberParams.areas;
        }
      }
      if (searchMemberParams.admission_date_from != [] && searchMemberParams.admission_date_from.length > 0 && searchMemberParams.admission_date_from[0].join('-') != '') {
        searchParams.admission_date_from = searchMemberParams.admission_date_from[0].join('-');
      }
      if (searchMemberParams.admission_date_to != [] && searchMemberParams.admission_date_to.length > 0 && searchMemberParams.admission_date_to[0].join('-') != '') {
        searchParams.admission_date_to = searchMemberParams.admission_date_to[0].join('-');
      }
      // user status. 0: active, 1: before active, 2: 休会, not set: active

      // active: 活動中
      // pre_entry: 開始前
      // breaking: 休会

      const status_hash = {
        "active": 0,
        "pre_entry": 1,
        "breaking": 2
      };
      if (searchMemberParams.status != []) {
        var statuses = searchMemberParams.status.map(st => { return status_hash[st] });
        searchParams.statuses = statuses;
      }

      return apiArt
        .get(`${API_URL}/communities`, {
          params: searchParams
        })
        .then(result => {
          context.commit("setGroups", result.data.members);
          return result;
        });
    },

    searchNormalChats(context, searchMemberParams) {
      const user_type_hash = {
        all: 0,
        member: 1,
        teacher: 2,
        staff: 3
      };

      var searchParams = { only_mine: searchMemberParams.only_friend };

      if (searchMemberParams.page_number > -1) {
        searchParams.page_number = searchMemberParams.page_number;
      }
      searchParams.page_items = 16;
      if (searchMemberParams.keyword != "") {
        searchParams.keyword = searchMemberParams.keyword;
      }
      if (
        searchMemberParams.user_type != "" &&
        searchMemberParams.user_type != "all"
      ) {
        searchParams.user_type = user_type_hash[searchMemberParams.user_type];
      }
      if (searchMemberParams.user_type != "staff") {
        if (searchMemberParams.course_id != []) {
          searchParams.course_ids = searchMemberParams.course_id;
        }
        if (searchMemberParams.studio_ids != []) {
          searchParams.studio_ids = searchMemberParams.studio_ids;
        }
        if (searchMemberParams.ranks != []) {
          searchParams.ranks = searchMemberParams.ranks;
        }
        if (searchMemberParams.areas != []) {
          searchParams.area_ids = searchMemberParams.areas;
        }
      }
      if (
        searchMemberParams.admission_date_from != [] &&
        searchMemberParams.admission_date_from.length > 0 &&
        searchMemberParams.admission_date_from[0].join("-") != ""
      ) {
        searchParams.admission_date_from = searchMemberParams.admission_date_from[0].join("-");
      }
      if (
        searchMemberParams.admission_date_to != [] &&
        searchMemberParams.admission_date_to.length > 0 &&
        searchMemberParams.admission_date_to[0].join("-") != ""
      ) {
        searchParams.admission_date_to = searchMemberParams.admission_date_to[0].join("-");
      }
      // user status. 0: active, 1: before active, 2: 休会, not set: active

      // active: 活動中
      // pre_entry: 開始前
      // breaking: 休会

      const status_hash = {
        active: 0,
        pre_entry: 1,
        breaking: 2
      };
      if (searchMemberParams.status != []) {
        var statuses = searchMemberParams.status.map(st => {
          return status_hash[st];
        });
        searchParams.statuses = statuses;
      }
      var response = null;
      return api
        .get(`/member_api/v1/followings/members`, {
          params: searchParams
        })
        .then((result => {
          var searchParams_art = { member_ids: result.data.result };
          return apiArt.get(`${API_URL}/members/info`, {
            params: searchParams_art
          }).then(result_art => {
            result.data.result = result_art.data.result;
            context.commit("setMembers", result_art.data.members);
            response = result
            return response;
          });
        }))
    },

    getFriendRequest(context) {
      return apiArt.get(`${API_URL}/friend_requests`).then(result => {
        context.commit("setFriendRequest", result.data);
      });
    },

    deleteFriendRequest(context, friend_request_id) {
      return apiArt.delete(`${API_URL}/friend_requests/${friend_request_id}`).then(result => {
        context.commit("setDeleteFriendRequest", friend_request_id);
      });
    },

    acceptFriendRequest(context, friend_request_id) {
      return apiArt.patch(`${API_URL}/friend_requests/${friend_request_id}`).then(result => {
        context.commit("setAcceptFriendRequest", friend_request_id);
      });
    },

    updateCommunity(context, formData) {
      return apiArt
        .post(`${API_URL}/communities/update`, formData)
        .then(result => {
          context.commit("setCommunity", result.data);
          return result;
        });
    },

    getCommunity(context, id) {
      return apiArt
        .get(`${API_URL}/communities/` + id)
        .then(result => {
          context.commit("setCommunity", result.data);
          return result;
        });
    },

    getJingleSounds(context) {
      return api.get("/api/v1/jingles/sounds").then(result => {
        context.commit("setJingleSounds", result.data);
      });
    },

    getSingleJingleSound(context, selectedSoundId) {
      return api.get(`/api/v1/jingles/sounds/${selectedSoundId}`).then(result => {
        context.commit("setSingleJingleSound", result.data);
      });
    },

    getJingleGenres(context) {
      return api.get("/api/v1/jingles/genres").then(result => {
        context.commit("setJingleGenres", result.data);
      });
    },

    getJingleBalloons(context) {
      return api.get("/api/v1/jingles/balloons").then(result => {
        context.commit("setJingleBalloons", result.data);
      });
    },

    getSingleJingleBalloon(context, selectedBalloonId) {
      return api.get(`/api/v1/jingles/balloons/${selectedBalloonId}`).then(result => {
        context.commit("setSingleJingleBalloon", result.data);
      });
    },

    createJingle(context, formData) {
      return api
        .post("/api/v1/jingles/jingles", formData)
        .then(result => {
          context.commit("setJingle", result.data);
        });
    },

    createJingleSound(context, formData) {
      return api
        .post("/api/v1/jingles/sounds", formData)
        .then(result => {
          context.commit("setCreatedJingleSound", result.data);
        });
    },

    getJingles(context, formData) {
      return api
        .get("/api/v1/jingles/jingles", formData)
        .then(result => {
          context.commit("setJinglesPerUser", result.data);
          return result;
        });
    },

    updateJingle(context, { formData, id }) {
      return api
        .put(`/api/v1/jingles/jingles/${id}`, formData)
        .then(result => {
          context.commit("setUpdatedJingle", result.data);
        });
    },

    deleteJingle(context, id) {
      return api
        .delete(`/api/v1/jingles/jingles/` + id)
        .then(result => {
          context.commit("setDeleteJingle", result.data);
        });
    },

    getMyOwnedRooms(context) {
      return api
        .get(`/member_api/v1/chat_rooms/mine`)
        .then(result => {
          context.commit("setMyOwnedRooms", result.data);
        });
    },
    addMemberToChatRoom(context, formData) {
      return api
        .post(`/member_api/v1/chat_rooms/add_member_to_chat_room`, formData)
    },
    startChatWithTeacher(context, formData) {
      return api
        .post(`/member_api/v1/chat_rooms/start_chat_with_teacher`, formData)
    },
  },

  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setMembers(state, members) {
      state.members = members;
    },
    setGroups(state, groups) {
      state.groups = groups;
    },
    setFollowingMembers(state, members) {
      if (state.communities.communities && state.communities.communities.length > 0 && !state.followings.includes(state.communities.communities[0])) {
        state.followings = members.concat(state.communities.communities);
      }
      else {
        state.followings = members
      }
      var followersSliced = [];
      var followers = state.followings;
      for (var i = 0, j = 0; i < followers.length; i += 7, j++) {
        followersSliced[j] = followers.slice(i, i + 7);
      }
      state.followingsSliced = followersSliced;
    },

    upsertFollowingMember(state, member) {
      const index = state.followings.findIndex(m => m.id === member.id);
      if (index >= 0) {
        state.followings[index].followee = {
          ...state.followings[index].followee,
          member
        };
      } else {
        state.followings = [
          ...state.followings,
          { id: member.id, followee: member }
        ];
      }
    },

    removeFollowingMember(state, member) {
      state.followings = [...state.followings.filter(m => m.id !== member.id)];
    },

    setActiveMember(state, member) {
      state.activeMember = member;
    },

    setActiveChatRoom(state, chatRoom) {
      state.activeChatRoom = chatRoom;
      state.isCommunity = state.activeChatRoom.member ? false : true;
    },

    updateMessages(state, messages) {
      if (state.activeChatRoom) {
        messages.forEach(m => {
          const index = state.activeChatRoom.messages.findIndex(e => e.id === m.id);
          if (index >= 0) {
            switch (m.status) {
              case 3:
                state.activeChatRoom.messages.splice(index, 1);
                break;
              default:
                Object.assign(state.activeChatRoom.messages[index], m);
                break;
            }
          } else if (m.status !== 3) {
            state.activeChatRoom.messages.push(m);
          }
        });
        state.activeChatRoom.messages = state.activeChatRoom.messages.sort((a, b) => a.timestamp - b.timestamp);
      }
    },

    updateReactions(state, msgId, reactions) {
      if (state.activeChatRoom) {
        const msg = state.activeChatRoom.messages.find(e => e.id === msgId);
        msg.reactions = reactions;
      }
    },

    updateLastMessage(state) {
      let idx = -1;
      if (state.activeChatRoom == null) {
        return;
      }
      const lastMessage =
        state.activeChatRoom.messages[state.activeChatRoom.messages.length - 1];
      if (state.isCommunity) {
        idx = state.followings.findIndex(
          f => typeof !state.activeChatRoom.member && f.community && f.community.id === state.activeChatRoom.id
        );
      } else {
        idx = state.followings.findIndex(
          f => typeof state.activeChatRoom.member !== 'undefined' && f.followee && f.followee.id === state.activeChatRoom.member.id
        );
      }
      if (idx >= 0) {
        state.followings[idx].lastMessage = lastMessage;
      }
    },

    closeChatRoom(state) {
      state.minimizedChatRooms = state.minimizedChatRooms.filter(e => !!e);
      const idx = state.activeChatRoom
        ? state.minimizedChatRooms.findIndex(
          r => r.id === state.activeChatRoom.id
        )
        : -1;
      if (idx >= 0) {
        state.minimizedChatRooms.splice(idx, 1, state.activeChatRoom);
      } else {
        state.minimizedChatRooms.push(state.activeChatRoom);
      }
      state.activeChatRoom = null;
    },

    openChatRoom(state, chatRoomId) {
      state.activeChatRoom = state.minimizedChatRooms.find(
        room => room.id === chatRoomId
      );
    },

    removeChatRoom(state, chatRoomId) {
      const idx = state.minimizedChatRooms.findIndex(
        room => room && room.id === chatRoomId
      );
      state.minimizedChatRooms.splice(idx, 1);
    },

    setMinimizedChatRooms(state, chatRooms) {
      chatRooms.forEach(r => {
        if ("member" in r) {
          const f = state.followings.find(e => e.id === r.member.id);
          if (typeof (f) === `undefined` || f === null) {
            r.member.relationship = "コミュニティ"
          }
          else {
            r.member.relationship = f.followee.relationship;
          }
        }
      });
      state.minimizedChatRooms = chatRooms;
    },

    setChatOptions(state, options) {
      state.chatOptions = options;
    },

    setVideoOptions(state, options) {
      state.videoOptions = options;
    },

    setCustomerSupportMembers(state, members) {
      state.supporters = members;
    },

    setRole(state, role) {
      state.role = role;
    },

    setMaster(state, master) {
      state.master = master;
    },

    setFriendRequest(state, friendRequest) {
      state.friendRequest = friendRequest;
    },

    setCommunities(state, communities) {
      state.communities = communities;
    },

    setDeleteFriendRequest(state, friend_request_id) {
      state.friendRequest = [...state.friendRequest.filter(m => m.id !== friend_request_id)];
    },

    setAcceptFriendRequest(state, friend_request_id) {
      state.friendRequest = [...state.friendRequest.filter(m => m.id !== friend_request_id)];
    },

    setCreateCommunity(state, createCommunity) {
      state.createCommunity = createCommunity;
    },

    setCreateFriendRequest(state, createFriendRequest) {
      state.createFriendRequest = createFriendRequest;
    },

    setCommunity(state, community) {
      state.community = community;
    },
    setJingleSounds(state, jingle_sounds) {
      state.jingleSounds = jingle_sounds;
    },
    setSingleJingleSound(state, jingle_sound) {
      state.singleJingleSound = jingle_sound;
    },
    setJingleGenres(state, jingle_genres) {
      state.jingleGenres = jingle_genres;
    },
    setJingleBalloons(state, jingle_balloons) {
      state.jingleBalloons = jingle_balloons;
    },
    setSingleJingleBalloon(state, jingle_balloon) {
      state.singleJingleBalloon = jingle_balloon;
    },
    setJingleMessage(state, jingleMessage) {
      state.jingleMessage = jingleMessage;
    },
    setJingle(state, jingle) {
      state.jingle = jingle;
    },
    setCreatedJingleSound(state, jingleOriginalSound) {
      state.jingleOriginalSound = jingleOriginalSound;
    },
    setScreenWidth(state, screenWidth) {
      state.screenWidth = screenWidth;
    },
    setJinglesPerUser(state, JinglesPerUser) {
      state.JinglesPerUser = JinglesPerUser;
    },
    setUpdatedJingle(state, updatedJingle) {
      state.updatedJingle = updatedJingle;
    },
    setDeleteJingle(state, deleteJingle) {
      state.deleteJingleData = deleteJingle;
    },
    JingleCreationModalState(state, value) {
      state.jingleModalVisible = value
    },
    JingleStepChange(state, value) {
      state.jingleStep = value
    },
    CheckEditJingle(state, value) {
      state.EditJinglePass = value
    },
    updateJingleId(state, id) {
      state.jingleId = id
    },
    setMyOwnedRooms(state, value) {
      state.myOwnedRooms = value
    },
  }
});

export { store };
