import axios from "axios";
axios.defaults.withCredentials = true

const apiArt = axios.create({
  withCredentials: true,
  headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Cache': "no-cache",
    }
});

export { apiArt }
