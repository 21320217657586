export default {
  methods: {
    shortFileName: function(fileName, maxBytes) {
      // 拡張子で分ける
      let imgExe = this.getExe(fileName);
      if (imgExe == "File") imgExe = "";
      let imgName = fileName.slice(0, -(imgExe.length + 1));
      const imgNameBytes = encodeURIComponent(imgName).replace(/%../g, "x")
        .length;
      // 元ファイル名とMax Byte数の比較
      if (imgNameBytes > maxBytes) {
        const zenkaku = imgNameBytes - imgName.length;
        if (zenkaku > 0) {
          imgName = imgName.slice(0, maxBytes / 2 - imgName.length) + "..";
        } else {
          imgName = imgName.slice(0, maxBytes - imgNameBytes) + "..";
        }
      }
      // 短くカットしたものと.と拡張子の文字列の連結
      imgName = imgName + "." + imgExe;
      return imgName;
    },
    getExe: function(fileName) {
      let fileExe = fileName.split(".").reverse()[0];
      if (fileExe == fileName) {
        return "File";
      }
      return fileExe.toLowerCase();
    },
    inject: function() {
      Array.prototype.run = function(f) {
        return f(this);
      };
      Array.prototype.cut = function(index, size) {
        return [...this.slice(0, index), ...this.slice(index + (size || 1))];
      };
      Array.prototype.group = function(getKey) {
        const map = {};
        this.forEach(i => {
          const key = getKey(i);
          if (!(key in map)) {
            map[key] = [];
          }
          map[key].push(i);
        });
        return map;
      };
      String.prototype.pad = function(padding) {
        return (padding + this).slice(-padding.length);
      };
      Number.prototype.pad = function(padding) {
        return (padding + this).slice(-padding.length);
      };
      Date.minutes = function(hhmm) {
        return hhmm
          .trim()
          .split(/[^0-9]/)
          .map(Number)
          .run(a => a[0] * 60 + a[1]);
      };
      Date.hhmm = function(minutes) {
        return (
          Math.floor(minutes / 60).pad("00") + ":" + (minutes % 60).pad("00")
        );
      };
      Date.prototype.in_time = function(
        from_text /*Date, or HH:MM*/,
        to_text /*Date, or HH:MM*/,
        truncate = 1000 * 60 /*分単位で比較*/
      ) {
        const base = new Date();
        base.setTime(this.getTime());
        base.setHours(0);
        base.setMinutes(0);
        base.setSeconds(0);
        base.setMilliseconds(0);
        const time = Math.floor(this.getTime() / truncate);
        const from_date = from_text ? new Date(from_text) : null;
        const from = isNaN(from_date)
          ? Math.floor(
              (base.getTime() + Date.minutes(from_text) * 60 * 1000) / truncate
            )
          : Math.floor(from_date.getTime() / truncate) || time;
        const to_date = to_text ? new Date(to_text) : null;
        const to = isNaN(to_date)
          ? Math.floor(
              (base.getTime() + Date.minutes(to_text) * 60 * 1000) / truncate
            )
          : Math.floor(to_date.getTime() / truncate) || time;
        return from <= time && time <= to;
      };
      Date.dayOfWeeks = ["日", "月", "火", "水", "木", "金", "土"];
      Date.prototype.getDayOfWeek = function() {
        return Date.dayOfWeeks[this.getDay()];
      };
      Date.prototype.offsetMonth = function(offset) {
        return new Date(
          this.getFullYear(),
          this.getMonth() + offset,
          this.getDate()
        );
      };
      Date.prototype.offsetDate = function(offset) {
        return new Date(
          this.getFullYear(),
          this.getMonth(),
          this.getDate() + offset
        );
      };
      Date.prototype.first_day = function() {
        return new Date(this.getFullYear(), this.getMonth(), 1);
      };
      Date.prototype.last_day = function() {
        return new Date(this.getFullYear(), this.getMonth() + 1, 0);
      };
      Date.fromArray = function(a) {
        const date = new Date();
        if (a.length > 0) date.setFullYear(a[0]);
        if (a.length > 1) date.setMonth(a[1] - 1);
        if (a.length > 2) date.setDate(a[2]);
        if (a.length > 4) date.setHours(a[4]);
        if (a.length > 5) date.setMinutes(a[5]);
        if (a.length > 6) date.setSeconds(a[6]);
        if (a.length > 7) date.setMilliseconds(a[7]);
        return date;
      };
      Date.prototype.toArray = function(n) {
        if (n == null) n = 8;
        const a = [];
        if (n > 0) a.push(this.getFullYear());
        if (n > 1) a.push(this.getMonth() + 1);
        if (n > 2) a.push(this.getDate());
        if (n > 3) a.push(this.getDay());
        if (n > 4) a.push(this.getHours());
        if (n > 5) a.push(this.getMinutes());
        if (n > 6) a.push(this.getSeconds());
        if (n > 7) a.push(this.getMilliseconds());
        return a;
      };
      Date.prototype.toUTCISO = function() {
        return (
          this.toJSON()
            .replace(/[^0-9T]+/g, "")
            .slice(0, 15) + "Z"
        );
      };
      Date.prototype.format = function(formatter, utc) {
        var month_en = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];
        var wday_en = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ];
        return formatter(
          utc
            ? {
                year: this.getUTCFullYear(),
                month: ("0" + (this.getUTCMonth() + 1)).slice(-2),
                month_en: month_en[this.getUTCMonth()],
                day: ("0" + this.getUTCDate()).slice(-2),
                wday: this.getUTCDay(),
                wday_ja: Date.dayOfWeeks[this.getUTCDay()],
                wday_en: wday_en[this.getUTCDay()],
                ampm_ja: this.getUTCHours() < 12 ? "午前" : "午後",
                hour: ("0" + this.getUTCHours()).slice(-2),
                hour12: ("0" + (this.getUTCHours() % 12)).slice(-2),
                minute: ("0" + this.getUTCMinutes()).slice(-2),
                second: ("0" + this.getUTCSeconds()).slice(-2),
                ms: ("00" + this.getUTCMilliseconds()).slice(-3)
              }
            : {
                year: this.getFullYear(),
                month: ("0" + (this.getMonth() + 1)).slice(-2),
                month_en: month_en[this.getMonth()],
                day: ("0" + this.getDate()).slice(-2),
                wday: this.getDay(),
                wday_ja: Date.dayOfWeeks[this.getDay()],
                wday_en: wday_en[this.getDay()],
                ampm_ja: this.getHours() < 12 ? "午前" : "午後",
                hour: ("0" + this.getHours()).slice(-2),
                hour12: ("0" + (this.getHours() % 12)).slice(-2),
                minute: ("0" + this.getMinutes()).slice(-2),
                second: ("0" + this.getSeconds()).slice(-2),
                ms: ("00" + this.getMilliseconds()).slice(-3)
              }
        );
      };
      Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
    },
    day_of_week(ymd) {
      return new Date(ymd[0], ymd[1] - 1, ymd[2]).getDayOfWeek();
    },
    array_equals(a1, a2, n) {
      if (!Array.isArray(a1) || !Array.isArray(a2)) return false;
      if (n == null) n = a1.length;
      for (let i = 0; i < n; i++) {
        if (a1[i] !== a2[i]) return false;
      }
      return true;
    },
    array_compare(a1, a2, n) {
      if (!Array.isArray(a1) || !Array.isArray(a2))
        return a1 < a2 ? -1 : a1 === a2 ? 0 : 1;
      if (n == null) n = a1.length;
      for (let i = 0; i < n; i++) {
        if (a1[i] < a2[i]) return -1;
        if (a1[i] > a2[i]) return 1;
      }
      return 0;
    }
  }
};
