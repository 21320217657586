<template>
  <div class="chat-room">
    <modal-background />
    <div class="chat-room--content">
      <modal-close-button
        class="chat-room--close-button"
        @click="onCloseButtonClick"
      />
      <div class="chat-room--header">
        <chat-member
          v-if="chatRoom"
          :current-user="currentUser"
          :member="chatRoom ? chatRoomMember : defaultMember"
          :relationship="
            chatRoom ? chatRoom.relationship : defaultMember.relationship
          "
          :communityImage="chatRoom.image ? chatRoom.image : null"
          :communityTitle="chatRoom.title ? chatRoom.title : null"
          @open-community-detail="openCommunityDetail"
          class="chat-room--member"
        />
        <button
          type="button"
          class="minimized-chat-room--close-button"
          @click="onCloseButtonClick"
        >
          <img
            :src="closeButtonIcon"
            alt="閉じる"
            class="minimized-chat-room--close-icon"
          />
        </button>
      </div>
      <div @scroll="onScroll" ref="chatRoomBody" class="chat-room--body">
        <div v-if="chatRoom">
          <div
            v-if="showLineNotifyStatus()"
            class="chat-room-line-notification"
          >
            <div class="chat-room-line-title">
              <img
                src="https://eys-file.s3-ap-northeast-1.amazonaws.com/gate/image/icon/line-icon.svg"
                alt=""
              />
              <p>新しいメッセージをLINE通知で受け取りますか。</p>
            </div>
            <div class="chat-room-line-choice">
              <button
                class="line-choice-auth"
                v-on:click="lineAuthButtonClick(chatRoom)"
              >
                はい
              </button>
              <button class="line-choice-close" @click="lineCloseButtonClick">
                いいえ
              </button>
              <button
                class="line-choice-never-see"
                @click="lineNeverSeeButtonClick"
              >
                いいえ、今後表示しない
              </button>
            </div>
          </div>
        </div>
        <div class="chat-room--messages">
          <div v-if="!chatRoom" :style="{ 'text-align': 'center' }">
            ロード中...
          </div>
          <div
            v-else
            v-for="(msg, index) in chatRoomMessages"
            :key="msg.id"
            class="chat-container"
            @mouseover="hoveredMessageId = msg.id"
            @mouseleave="reactToMessageId = null"
          >
            <div
              class="user-container"
              v-if="
                !msg.from &&
                isFirstMessage(msg, index) &&
                msg.member_id != currentUser.mother_id &&
                msg.member_id
              "
            >
              <div class="member-icon chat-member--icon small">
                <img
                  v-if="msg.to || msg.member_id != currentUser.mother_id"
                  :src="
                    chatRoom
                      ? getMemberImage(msg.member_id, msg.id)
                      : defaultMember.avatar_path
                  "
                  :alt="
                    chatRoom
                      ? getMemberName(msg.member_id)
                      : defaultMember.firstName
                  "
                  :ref="'image' + msg.id"
                  class="member-icon--image"
                />
              </div>
              <span v-if="chatRoom.title" class="chat-member--name">{{
                chatRoom
                  ? getMemberName(msg.member_id)
                  : defaultMember.firstName
              }}</span>
              <span v-else class="chat-member--name">{{
                chatRoom ? chatRoom.member.firstName : defaultMember.firstName
              }}</span>
            </div>

            <div
              v-if="msg.ref && msg.ref.type === 'reply'"
              class="replied-message"
            >
              <span :style="{ fontSize: '80%' }">
                <span
                  v-if="msg.ref.message.member_id === currentUser.mother_id"
                >
                  <strong>自分</strong>：
                </span>
                <span v-else>
                  <strong>{{ msg.ref.message.member_name }}</strong>
                  さん：
                </span>
              </span>
              <hr :style="{ margin: '0.25rem 0' }" />
              <span v-html="msg.ref.message.body"></span>
            </div>

            <div
              class="chat-message"
              :class="[
                msg.member_id
                  ? {
                      'from-me': msg.member_id === currentUser.mother_id,
                      'to-me': msg.member_id != currentUser.mother_id,
                      'is-file': msg.files && msg.files.length > 0,
                    }
                  : {
                      'from-me': msg.from,
                      'to-me': msg.to,
                      'is-file': msg.file_array && msg.file_array.length > 0,
                    },
              ]"
            >
              <span v-html="msg.body" :style="{ width: '100%' }"></span>

              <div class="message-reactions">
                <span v-for="(r, i) in msg.reactions" :key="i">
                  <fa
                    :icon="r.package ? [r.package, r.icon] : r.icon"
                    :style="{ color: getReactionColor(r) }"
                  />
                  {{ r.count > 1 ? r.count : "" }}
                </span>
              </div>

              <span class="time-stamp additional-info">
                <span class="btn-group" v-show="msg.id === hoveredMessageId">
                  <fa
                    class="btn-icon"
                    :icon="['far', 'smile']"
                    @click="
                      reactToMessageId = reactToMessageId ? null : msg.id;
                      hoveredMessageId = null;
                    "
                  />
                  <fa
                    class="btn-icon"
                    icon="reply"
                    @click="
                      replyToMessageId = msg.id;
                      hoveredMessageId = null;
                      chatBodyChange(2);
                    "
                  />
                </span>
                <span>
                  {{
                    new Date(msg.timestamp)
                      | moment(
                        isToday(msg.timestamp) ? "HH:mm" : "YYYY/MM/DD HH:mm"
                      )
                  }}
                </span>
              </span>
              <div
                v-for="file in msg.file_array ? msg.file_array : msg.files"
                :key="file.id"
                :class="{
                  'chat-item chat-room-filelist': file.name != 'recording.wav',
                  'chat-item chat-room-audiofile': file.name == 'recording.wav',
                }"
              >
                <img
                  :src="downloadIcon"
                  v-if="
                    (msg.member_id === currentUser.mother_id &&
                      file.name != 'recording.wav') ||
                    (msg.from && file.name != 'recording.wav')
                  "
                  alt="Download"
                  class="chat-room-download-icon"
                  @click="downloadFile(file.path, file.name)"
                />
                <div class="chat-room-image">
                  <video
                    class="video"
                    :id="msg.id"
                    preload="none"
                    type="video/mp4"
                    style="display: flex; border-radius: 15px"
                    v-if="file.name == 'captured-video.mp4'"
                    :src="file.path"
                    width="100%"
                    controls
                    disablePictureInPicture
                    controlslist="nodownload"
                  />
                  <audio
                    :id="msg.id"
                    preload="none"
                    type="audio/x-wav"
                    style="display: flex; margin-left: 0px"
                    v-if="file.name == 'recording.wav'"
                    :src="file.path"
                    controls
                    controlslist="nodownload"
                  />
                  <img
                    v-if="isImage(file)"
                    :src="file.path"
                    alt=""
                    class="chat-room-img-file"
                    @click="onFileClick(file.path)"
                    loading="lazy"
                  />
                  <div
                    v-else-if="
                      file.name != 'recording.wav' &&
                      file.name != 'captured-video.mp4'
                    "
                    class="chat-room-not-img-file"
                  >
                    {{ getExe(file.name) }}
                  </div>
                  <div
                    v-if="
                      file.name != 'recording.wav' &&
                      file.name != 'captured-video.mp4'
                    "
                  >
                    {{ shortFileName(file.name, 22) }}
                  </div>
                </div>
                <img
                  :src="downloadIcon"
                  v-if="msg.to && file.name != 'recording.wav'"
                  alt="Download"
                  class="chat-room-download-icon"
                  @click="downloadFile(file.path, file.name)"
                />
                <img
                  :src="downloadIcon"
                  v-if="
                    msg.member_id &&
                    msg.member_id != currentUser.mother_id &&
                    file.name != 'recording.wav'
                  "
                  alt="Download"
                  class="chat-room-download-icon"
                  @click="downloadFile(file.path, file.name)"
                />
              </div>
            </div>

            <div
              class="reaction-buttons btn-group"
              v-show="reactToMessageId === msg.id"
            >
              <span
                v-for="(r, i) in reactions"
                :key="i"
                :style="{ position: 'relative' }"
                @click="addReaction(msg, r)"
              >
                <fa
                  :icon="r.package ? [r.package, r.icon] : r.icon"
                  class="btn-icon"
                  :style="{ color: r.color, background: 'transparent' }"
                />
                <span
                  v-show="isReactionCreatedByMe(msg, r)"
                  :style="{
                    position: 'absolute',
                    left: '3px',
                    top: '50%',
                    fontSize: 'medium',
                  }"
                >
                  ・
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-room--footer">
        <div v-if="isShowArrow" class="scroll-arrow">
          <img
            :src="arrowIcon"
            alt="scroll"
            class="chat-room-scroll-arrow"
            @click="scrollToLastMessage()"
          />
        </div>
        <message-input
          :disabled="!chatRoom"
          :message.sync="message"
          :followings="
            followings.filter((following) => following.followee != undefined)
          "
          :chatRoomMembers="chatRoomMembers"
          :fileList.sync="fileList"
          :mentions.sync="mentions"
          :video-limit="videoLimit"
          :ref-message="refMessage"
          :chatLoading="chatLoading"
          @upload-file-set="chatBodyChange(1)"
          @upload-file-remove="chatBodyChange(0)"
          @reply-set="chatBodyChange(2)"
          @send-button-click="raiseSendEvent"
          @clear-ref-message="replyToMessageId = null"
        />
      </div>
    </div>
    <modal-image
      v-if="modalPreviewShow"
      :uploadImage="modalImageFilePath"
      @close-button-click="closeModalImage"
    />
  </div>
</template>

<script>
const ChatMember = () => import("./ChatMember");
const MessageInput = () => import("./MessageInput");
const ModalBackground = () => import("./ModalBackground");
const ModalCloseButton = () => import("./ModalCloseButton");
const ModalImage = () => import("./ModalImage");

import CloseIcon from "../assets/images/small-close.png";
import DownloadIcon from "../assets/images/icon-download.png";
import ArrowIcon from "../assets/images/arrow_down.svg";
import { fixBackground, activateBackgroundScroll } from "../utils/modal";
import utilsMixin from "../packs/mixins/utils";
import { api } from "../api";
import moment from "moment";

export default {
  name: "ChatRoom",
  components: {
    ChatMember,
    MessageInput,
    ModalBackground,
    ModalCloseButton,
    ModalImage,
  },
  mixins: [utilsMixin],
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
    chatRoom: {
      type: Object,
      default: () => null,
    },
    defaultMember: {
      type: Object,
      default: () => null,
    },
    followings: {
      type: Array,
      default: [],
    },
    pollingFunc: {
      type: Function,
      default: null,
    },
    videoLimit: {
      type: Number,
      default: () => 1,
    },
    chatLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: null,
      intervalId: null,
      positionY: null,
      bodyHeight: "calc(100% - 220px)",
      fileList: [],
      mentions: [],
      modalPreviewShow: false,
      modalImageFilePath: null,
      isShowArrow: false,
      isScroll: true,
      today: moment().unix(),
      lastMessageId: null,
      sendCommunityFirstMessage: true,
      replyToMessageId: null,
      reactToMessageId: null,
      hoveredMessageId: null,
      showActionButtons: false,
      fetching: false,
      messageLimit: 20,
      messageUp: 0,
    };
  },
  mounted() {
    if (this.pollingFunc) {
      this.intervalId = setInterval(async () => {
        if (!this.fetching) {
          this.fetching = true;
          try {
            const response = await this.pollingFunc();
            if (response && response.data && !response.data.messages) {
              if (response.data.length == 1) {
                if (!this.lastMessageId) {
                  this.lastMessageId =
                    response.data[response.data.length - 1].id;
                }
              } else if (response.data.length > 1) {
                if (
                  !this.isScroll &&
                  response.data.findIndex(
                    (e) => e.id > (this.lastMessageId || 0)
                  ) >= 0
                ) {
                  this.isShowArrow = true;
                }

                const arrivingMessageId = response.data[0].id;
                if (!this.lastMessageId) {
                  this.lastMessageId = arrivingMessageId;
                } else {
                  if (this.lastMessageId != arrivingMessageId) {
                    this.lastMessageId = arrivingMessageId;
                    this.$root.$emit("new-message-arrived");
                  }
                }
              }
              if (
                response.data.length == 0 &&
                this.chatRoom.image &&
                this.sendCommunityFirstMessage
              ) {
                this.sendCommunityFirstMessage = false;
                this.$emit(
                  "message-send",
                  `コミュニティ「${this.chatRoom.title}」が作成されました。`,
                  [],
                  [0]
                );
                this.message = null;
                this.mentions = [];
                this.fileList = [0];
              }
              if (this.isScroll) {
                this.scrollToLastMessage();
              }
            }
            // private chat response
            if (response && response.data.messages) {
              if (response.data.messages.length == 1) {
                if (!this.lastMessageId) {
                  this.lastMessageId =
                    response.data.messages[
                      response.data.messages.length - 1
                    ].id;
                }
              }
              if (response.data.messages.length > 1) {
                const arrivingMessageId =
                  response.data.messages[response.data.messages.length - 1].id;
                if (!this.lastMessageId) {
                  this.lastMessageId = arrivingMessageId;
                } else if (this.lastMessageId) {
                  if (this.lastMessageId != arrivingMessageId) {
                    this.lastMessageId = arrivingMessageId;
                    this.$root.$emit("new-message-arrived");
                  }
                }
                if (!this.isScroll) {
                  this.isShowArrow = true;
                }
              }
              if (this.isScroll) {
                this.scrollToLastMessage();
              }
            }
          } catch (error) {
            console.error(error);
          } finally {
            this.fetching = false;
          }
        }
      }, 10000);
    }
    this.positionY = fixBackground();

    var audios = document.getElementsByClassName("audio");
    [...audios].map((audio) => {
      audio.addEventListener(
        "touchstart",
        function (e) {
          e.target.play();
        },
        false
      );
    });

    var animation = document.getElementsByClassName("animation");
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    activateBackgroundScroll(this.positionY);
  },
  updated: function () {
    if (!this.lastMessageId) {
      this.scrollToLastMessage();
      if (this.chatRoom && this.chatRoom.messages) {
        const lastMsg =
          this.chatRoom.messages[this.chatRoom.messages.length - 1];
        if (lastMsg) {
          this.lastMessageId = lastMsg.id;
        }
      }
    }
  },
  computed: {
    closeButtonIcon() {
      return CloseIcon;
    },
    downloadIcon() {
      return DownloadIcon;
    },
    arrowIcon() {
      return ArrowIcon;
    },
    chatRoomMember() {
      if (this.chatRoom.image) {
        return null;
      } else {
        let chatMember = null;
        if (this.chatRoom.members && this.chatRoom.members.length == 2) {
          this.chatRoom.members.forEach((member) => {
            if (member.id != this.currentUser.mother_id) {
              chatMember = member;
            }
          });
        } else if (this.chatRoom.member) {
          chatMember = this.chatRoom.member;
        }
        return chatMember;
      }
    },
    chatRoomMembers() {
      return this.chatRoom && this.chatRoom.members
        ? this.chatRoom.members.filter(
            (chatMember) => chatMember.id != this.currentUser.mother_id
          )
        : [];
    },
    reactions() {
      return [
        {
          icon: "thumbs-up",
          package: "fas",
          color: "gold",
        },
        {
          icon: "thumbs-down",
          package: "fas",
          color: "gold",
        },
        {
          icon: "heart",
          package: "fas",
          color: "crimson",
        },
        {
          icon: "heart-broken",
          package: "fas",
          color: "crimson",
        },
        {
          icon: "smile",
          package: "fas",
          color: "gold",
        },
        {
          icon: "surprise",
          package: "fas",
          color: "gold",
        },
        {
          icon: "frown",
          package: "fas",
          color: "gold",
        },
        {
          icon: "angry",
          package: "fas",
          color: "gold",
        },
      ];
    },

    refMessage() {
      const msg = this.chatRoom
        ? this.chatRoom.messages.find((e) => e.id === this.replyToMessageId)
        : null;
      if (msg) {
        const { id, body } = msg;
        const member = {};

        if (this.chatRoom.members) {
          member.id = msg.member_id;
          if (msg.member_id === this.currentUser.mother_id) {
            member.name = "自分";
          } else if (this.chatRoom.members) {
            const m = this.chatRoom.members.find((e) => e.id === msg.member_id);
            member.name = m ? m.name + "さん" : "メンバー";
          } else {
            member.name = "メンバー";
          }
        } else if (this.chatRoom.member) {
          if (msg.to) {
            member.id = this.chatRoom.member.id;
            member.name =
              (this.chatRoom.member.teacherName ||
                `${this.chatRoom.member.familyName} ${this.chatRoom.member.firstName}` ||
                this.chatRoom.member.name) + "さん";
          } else {
            member.id = this.currentUser.mother_id;
            member.name = "自分";
          }
        }
        return { id, body, member };
      }
      return null;
    },

    chatRoomMessages() {
      let count = this.messageLimit + this.messageUp;
      return this.chatRoom.messages.slice(-(count));
    },
  },
  methods: {
    onCloseButtonClick() {
      this.$emit("close-button-click");
      let { roomId } = this.$route.query;
      if (roomId) {
        this.$router.replace("/");
      }
    },
    raiseSendEvent() {
      this.$emit(
        "message-send",
        this.message,
        this.mentions,
        this.fileList,
        this.replyToMessageId
      );
      this.message = null;
      this.mentions = [];
      this.fileList = [0];
    },
    scrollToLastMessage() {
      this.$nextTick(() => {
        const el = this.$refs.chatRoomBody;
        if (el) {
          el.scrollTop = el.scrollHeight;
        }
      });
      this.isShowArrow = false;
    },
    chatBodyChange(val) {
      if (val == 1) {
        this.bodyHeight = "calc(100% - 270px)";
      }
      if (val == 2) {
        this.bodyHeight = "calc(100% - 320px)";
      } else {
        this.bodyHeight = "calc(100% - 220px)";
      }
    },
    //XML file request
    downloadFile(path, name) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", path, true);
      xhr.responseType = "blob";
      xhr.onload = function (e) {
        if (this.status == 200) {
          var blob = new Blob([this.response]);
          var link = document.createElement("a");
          link.style = "display: none";
          document.body.appendChild(link);
          var urlUtil = window.URL || window.webkitURL;
          var fileUrl = urlUtil.createObjectURL(blob);
          link.href = fileUrl;
          link.download = name;
          link.click();
          window.URL.revokeObjectURL(fileUrl);
        }
      };
      xhr.send();
    },
    onFileClick(path) {
      console.log("this clicked onFIleClick");
      this.modalImageFilePath = path;
      this.modalPreviewShow = true;
    },
    closeModalImage() {
      this.modalPreviewShow = false;
    },
    showLineNotifyStatus() {
      return this.currentUser.line_notify_status_id == null;
    },
    lineAuthButtonClick(chatroom) {
      this.$router.replace(
        "/members/line_notification/auth?room_id=" + chatroom.id
      );
      this.$router.go();
    },
    lineCloseButtonClick() {
      document.querySelector(".chat-room-line-notification").style.display =
        "none";
    },
    lineNeverSeeButtonClick() {
      document.querySelector(".chat-room-line-notification").style.display =
        "none";
      api.post("/member_api/v1/me/never_show_line_notify").then((result) => {
        this.currentUser.line_notify_status_id = 2; // never show
      });
    },
    isImage(file) {
      var ext = this.getExe(file.name);
      return ext == "gif" || ext == "png" || ext == "jpg" || ext == "jpeg";
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.isShowArrow = false;
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
      if (scrollTop < 50) {
        const prevY = scrollHeight;
        this.messageUp += this.messageLimit;
        this.$nextTick(() => {
          const el = this.$refs.chatRoomBody;
          if (el) {
            const currentY = el.scrollHeight;
            el.scrollTop = currentY - prevY;
          }
        });
      }
    },
    isSameDate(previousTimestamp, currentTimestamp) {
      return (
        moment(previousTimestamp, "YYYY/MM/DD HH:mm").format(
          "YYYY/MM/DD HH:mm"
        ) ===
        moment(currentTimestamp, "YYYY/MM/DD HH:mm").format("YYYY/MM/DD HH:mm")
      );
    },
    isToday(timeStamp) {
      let msgDate = new Date(timeStamp);
      let todayDate = new Date();
      return moment(msgDate).format("L") === moment(todayDate).format("L");
    },
    getMemberImage(member_id, message_id) {
      var image = null;
      if (member_id) {
        for (let member of this.chatRoom.members) {
          if (member.id === member_id) {
            image = member.image;
            break;
          }
        }
        if (!image) {
          if (
            this.$refs["image" + message_id] &&
            this.$refs["image" + message_id].length > 0
          ) {
            this.$refs["image" + message_id][0].style =
              "padding: 2px 5px 5px 5px;";
          }
          image =
            "https://eys-file-dev.s3-ap-northeast-1.amazonaws.com/menu/user_action/icon_user_w.svg";
        }
        return image;
      } else {
        return this.chatRoom.member.avatar_path;
      }
    },
    getMemberName(member_id) {
      if (member_id) {
        let name = null;
        this.chatRoom.members.forEach((member) => {
          if (member.id === member_id) {
            name = member.name;
          }
        });
        return name;
      } else {
        return `${this.chatRoom.member.lastName} ${this.chatRoom.member.firstName}`;
      }
    },
    isFirstMessage(msg, index) {
      let isFirst = false;
      if (this.chatRoom.image) {
        if (index == 0 && msg.member_id != this.currentUser.mother_id) {
          isFirst = true;
        } else if (
          index > 0 &&
          this.chatRoom.messages[index - 1].member_id != msg.member_id
        ) {
          isFirst = true;
        } else {
          isFirst = false;
        }
      } else {
        if (
          (index == 0 && msg.to) ||
          (index == 0 && msg.member_id != this.currentUser.mother_id)
        ) {
          isFirst = true;
        } else if (
          (index > 0 &&
            this.chatRoom.messages[index - 1].member_id != msg.member_id) ||
          (index > 0 && this.chatRoom.messages[index - 1].from)
        ) {
          isFirst = true;
        } else {
          isFirst = false;
        }
      }
      return isFirst;
    },
    openCommunityDetail() {
      this.onCloseButtonClick();
      this.$root.$emit("showCommunityProfile", this.chatRoom);
    },

    addReaction(message, reaction) {
      this.$emit(
        "reaction-send",
        message,
        this.isReactionCreatedByMe(message, reaction)
          ? { icon: "", package: "" }
          : reaction
      );
      this.reactToMessageId = null;
    },

    getReactionColor(reaction) {
      const r = this.reactions.find((e) => e.icon === reaction.icon);
      return (r && r.color) || "black";
    },

    isReactionCreatedByMe(message, reaction) {
      const { reactions } = message;
      return reactions
        ? reactions.findIndex(
            (e) =>
              e.icon === reaction.icon &&
              e.package === reaction.package &&
              e.members.includes(this.currentUser.id)
          ) >= 0
        : false;
    },
  },
};
</script>

<style scoped>
.chat-room {
  position: relative;
  background-color: rebeccapurple;
}

.chat-room--content {
  width: 460px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 30px 0 0 30px;
  z-index: 100;
}

.chat-room--close-button {
  position: absolute;
  left: -64px;
  top: 20px;
}

.chat-room--header {
  top: 0;
  height: 60px;
  padding-left: 10px;
  box-shadow: 0px 0px 7px gray;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-room-line-notification {
  opacity: 0;
  background-color: #00b900;
  position: fixed;
  overflow: hidden;
  width: 460px;
  height: 88px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  animation: line-notification 0.4s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  z-index: 1;
}

@keyframes line-notification {
  from {
    opacity: 1;
    height: 0px;
  }
  to {
    opacity: 1;
    height: 88px;
  }
}
@media screen and (max-width: 576px) {
  .chat-room-line-notification {
    width: 100%;
  }
}
.chat-room-line-title {
  display: flex;
  justify-content: start;
  align-content: center;
  margin-top: 18px;
}
.chat-room-line-title p {
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  font-family: HiraginoSans, Meiryo, Arial, sans-serif;
  margin-bottom: 10px;
}
.chat-room-line-title img {
  height: 34.8px;
  widows: 36.1px;
  margin: -5px 10px 0 10px;
}
.chat-room-line-choice {
  display: flex;
  justify-content: center;
}
.chat-room-line-choice button {
  border-radius: 15px;
  font-size: 12px;
  font-family: HiraginoSans, Meiryo, Arial, sans-serif;
  color: #00b923;
  background-color: #ffffff;
  padding: 6px 17px;
  border: none;
  margin: 0 10px;
  cursor: pointer;
  box-shadow: 0 5px 10px rgb(0 0 0 / 25%);
}
@media screen and (min-width: 576px) {
  .chat-room-line-choice button {
    padding: 6px 25px;
  }
}
.chat-room--member {
  height: 60px;
}

.chat-room--messages {
  overflow-x: hidden;
}

.chat-room--body {
  margin-bottom: 10px;
  overflow: scroll;
  height: 100%;
}

.chat-room--footer {
  position: relative;
  bottom: 0.5rem;
  display: flex;
  justify-content: center;
}

.chat-room--footer .message-input {
  position: relative;
  width: 96%;
}

.chat-container {
  position: relative;
  margin-top: 10px;
}

.chat-message {
  width: 75%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 1.85;
  color: #222222;
  padding: 1rem;
  min-height: 50px;
}

.chat-message.to-me {
  position: relative;
  margin-left: 10px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  word-break: break-word;
}

.chat-message.to-me .time-stamp {
  width: 6rem;
  position: absolute;
  right: -31%;
  bottom: 0;
  color: #000000;
  opacity: 0.35;
  text-align: center;
  font-size: 9px;
}

.chat-message.to-me .actions {
  position: absolute;
  bottom: 0;
  right: -5rem;
}

.chat-message.to-me .actions .btn-action {
  cursor: pointer;
  border-width: 0px;
  border-style: none;
  background-image: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
  height: 2rem;
  width: 2rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  padding: 0px;
  font-size: 1.25rem;
  line-height: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.chat-message.from-me {
  position: relative;
  margin-left: 23%;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(to right, #bbbbff 0%, #66bbff 100%);
  color: #ffffff;
  word-break: break-word;
}

.chat-message.from-me .time-stamp {
  width: 6rem;
  position: absolute;
  left: -31%;
  bottom: 0;
  text-align: center;
  color: #000000;
  opacity: 0.35;
  font-size: 9px;
}

.chat-message.is-file {
  display: block !important;
}

.scroll-arrow {
  position: absolute;
  left: 45%;
  top: -40%;
}
.scroll-arrow img {
  width: 40px;
  z-index: 1;
  opacity: 0.5;
}
.chat-message.to-me.is-file .time-stamp {
  width: 6rem;
  position: absolute;
  right: -31%;
  bottom: 0;
  color: #000000;
  opacity: 0.35;
  text-align: center;
  font-size: 9px;
}

.chat-message.from-me.is-file .time-stamp {
  width: 6rem;
  position: absolute;
  left: -31%;
  bottom: 0;
  text-align: center;
  color: #000000;
  opacity: 0.35;
  font-size: 9px;
}

.chat-room-filelist {
  line-height: 30px;
  display: flex;
  align-items: center;
}
.chat-room-audiofile {
  line-height: 30px;
}
.chat-room-img-file {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}
.chat-item + .chat-item {
  margin-top: 10px;
}

.chat-room-not-img-file {
  width: 30px;
  height: 30px;
  border: 1px solid;
  border-radius: 5px;
  text-align: center;
  line-height: 30px;
  background: darkgray;
  float: left;
  margin-right: 5px;
}
.from-me .chat-room-download-icon {
  margin-left: -50px;
}
.from-me .chat-room-image {
  margin-left: 18px;
}
.to-me .chat-room-download-icon {
  margin-right: 0;
}
.to-me .chat-room-image {
  margin-right: 40px;
  width: 95%;
}
.chat-room-audiofile .chat-room-image {
  margin-left: 0px;
}
.minimized-chat-room--close-button {
  display: none;
  border: none;
  background-color: transparent;
}

.minimized-chat-room--close-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

@media screen and (max-width: 576px) {
  .chat-room--content {
    width: 100%;
    border-radius: 0px;
  }

  .minimized-chat-room--close-button {
    display: block;
  }
}

/* MEMBER ICON on chat-message */
.user-container {
  width: 70%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 1.85;
  color: #222222;
  padding: 10px;
  min-height: 50px;
}

.user-container .chat-member--name {
  margin-left: 8px;
}

.member-icon {
  position: relative;
}

.member-icon--online {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #44dd44;
  border-radius: 50%;
}

.member-icon.large {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #eeeeee;
}

.member-icon.large .member-icon--online {
  width: 40px;
  height: 40px;
}

.member-icon.normal {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 4px solid #ffffff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #eeeeee;
}

.member-icon.normal .member-icon--online {
  width: 20px;
  height: 20px;
}

.member-icon.small {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #eeeeee;
}

.member-icon.size-80 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #eeeeee;
}

.member-icon.size-90 {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #eeeeee;
}

.member-icon.size-100 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #eeeeee;
}

.member-icon.size-120 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #eeeeee;
}

.member-icon.normal .member-icon--online {
  width: 14px;
  height: 14px;
}

.member-icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.additional-info {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.btn-group {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.btn-icon {
  font-size: large;
  margin: 2px;
}

.btn-icon:hover {
  cursor: pointer;
}

.reaction-buttons {
  background: silver;
  border-radius: 999px;
  width: 50%;
  margin: 2px auto;
  padding: 2px;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
}

.message-reactions {
  position: absolute;
  bottom: -0.5rem;
  left: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: black;
}

.replied-message {
  position: relative;
  top: 0.5rem;
  left: 25%;
  width: 70%;
  background: lightgray;
  opacity: 0.8;
  font-size: small;
  border-radius: 1rem;
  padding: 1rem 0.5rem;
}

@media screen and (max-width: 836px) {
  .member-icon.size-80 {
    width: 50px;
    height: 50px;
  }

  .member-icon.size-90 {
    width: 60px;
    height: 60px;
  }

  .member-icon.size-100 {
    width: 70px;
    height: 70px;
  }

  .member-icon.size-120 {
    width: 80px;
    height: 80px;
  }

  .member-icon.large {
    width: 110px;
    height: 110px;
  }
}

@media screen and (max-width: 576px) {
  .member-icon.size-80 {
    width: 40px;
    height: 40px;
  }

  .member-icon.size-90 {
    width: 50px;
    height: 50px;
  }

  .member-icon.size-100 {
    width: 60px;
    height: 60px;
  }

  .member-icon.size-120 {
    width: 70px;
    height: 70px;
  }

  .member-icon.large {
    width: 100px;
    height: 100px;
  }
}
</style>
