<template>
  <div id="app">
    <div class="top" id="top">
      <div class="hello">
        <div
          v-if="false && currentUser && currentUser.staff"
          class="top--search-box"
        >
          <search-box @search-click="showSearchModal(false)" />
        </div>
        <img src="/HELLO.svg" alt="HELLO" />
      </div>
      <div class="friend-request" v-if="friendRequest.length">
        <friend-request
          @open-friend-list="openFriendRequests"
          :friend-request="friendRequest"
        />
      </div>
      <div
        class="friend-request-item"
        v-if="!showFriendRequest && friendRequest.length"
      >
        <friend-request-item
          @open-friend-list="openFriendRequests"
          @delete-friend-request="deleteMemberFriendRequest"
          @accept-friend-request="acceptMemberFriendRequest"
          :friend-request="friendRequest"
        />
      </div>
      <div class="school-list" v-if="currentUser">
        <school-list :current-user="currentUser" />
      </div>
      <swiper
        class="slider-sp"
        ref="slider-sp"
        :options="swiperOptionSP"
        :style="{
          '--swiper-pagination-color': '#6dbbfe',
          '--swiper-navigation-color': '#6dbbfe'
        }"
      >
        <template v-for="f in followings">
          <swiper-slide
            :key="f.id"
            v-bind:class="{
              'single-swiper': followings.length == 1,
              'double-swiper': followings.length >= 2
            }"
          >
            <div class="following-members">
              <following-member
                :key="f.id"
                :current-user="currentUser"
                :following="f"
                class="following-members--item"
                @member-click="showMemberProfile"
                @action-button-click="startChatWith"
              />
            </div>
          </swiper-slide>
        </template>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <swiper
        class="slider-pc"
        ref="slider-pc"
        :options="swiperOption"
        :style="{
          '--swiper-pagination-color': '#6dbbfe',
          '--swiper-navigation-color': '#6dbbfe'
        }"
      >
        <template v-for="(parent, parentIndex) in followingsSliced">
          <swiper-slide :key="parentIndex">
            <div class="following-members" :key="parentIndex">
              <template v-for="(f, index) in followingsSliced[parentIndex]">
                <following-member
                  :key="f.id"
                  :current-user="currentUser"
                  :following="f"
                  :style="[
                    index < followingsSliced[parentIndex].length / 2 - 1 &&
                    followingsSliced[parentIndex].length % 2 == 1 &&
                    followingsSliced[parentIndex].length > 3
                      ? index == 0
                        ? {
                            display: 'flex',
                            'justify-content': 'flex-start',
                            '--divisor':
                              followingsSliced[parentIndex].length / 2 -
                              1 +
                              ((followingsSliced[parentIndex].length / 2 - 1) %
                                1)
                          }
                        : index ==
                          followingsSliced[parentIndex].length / 2 -
                            1 -
                            ((followingsSliced[parentIndex].length / 2 - 1) % 1)
                        ? {
                            display: 'flex',
                            'justify-content': 'flex-end',
                            '--divisor':
                              followingsSliced[parentIndex].length / 2 -
                              1 +
                              ((followingsSliced[parentIndex].length / 2 - 1) %
                                1)
                          }
                        : {
                            '--divisor':
                              followingsSliced[parentIndex].length / 2 +
                              1 -
                              ((followingsSliced[parentIndex].length / 2) % 1)
                          }
                      : {
                          '--divisor':
                            followingsSliced[parentIndex].length / 2 +
                            1 +
                            ((followingsSliced[parentIndex].length / 2) % 1)
                        }
                  ]"
                  class="following-members--item"
                  @member-click="showMemberProfile"
                  @action-button-click="startChatWith"
                />
              </template>
            </div>
          </swiper-slide>
        </template>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <div v-if="minimizedChatRooms.length" class="minimized-chat-rooms">
        <minimized-chat-room
          v-for="(room, i) in minimizedChatRooms"
          :key="i"
          :current-user="currentUser"
          :chat-room="room"
          class="minimized-chat-rooms--item"
          @click="openChatRoom"
          @close-button-click="removeChatRoom"
        />
      </div>
    </div>
    <div></div>
    <div class="center-info">
      <img
        src="/your-2nd-community-logo.png"
        class="center-info--logo"
        alt="Your 2nd Community"
      />
      <div class="center-info--members">
        <div class="center-info--members-count">
          <div v-if="memberCount > 0">
            <span class="center-info--member-count">{{ memberCount }}</span>
            {{
              communityCount > 0 || artistCount > 0
                ? "人のメンバー、"
                : "メンバーがいます"
            }}
          </div>
          <div v-if="communityCount > 0">
            <span class="center-info--member-count">{{ communityCount }}</span>
            {{ artistCount > 0 ? "コミュニティ、" : "コミュニティがあります" }}
          </div>
        </div>
        <div v-if="artistCount > 0">
          <span class="center-info--member-count">{{ artistCount }}</span>
          人のInstructorがいます
        </div>
      </div>
      <div v-if="currentUser && communityCount" class="center-info--my-list">
        <friend-list
          :key="counterKey"
          :onlyFriends="true"
          :isStaff="currentUser.staff"
          :friendsCount="communityCount + memberCount + artistCount"
          @friendlist-click="showSearchModal(true, false, true)"
          @create-community-click="showSearchModal(true, true)"
        />
      </div>
      <!-- <user-relation-image
        :current-user="currentUser"
        :relational-members="relationalMembers"
        class="center-panel-image"
      /> -->
      <div class="customer-support-container">
        <customer-support-members
          :current-user="currentUser"
          :supporters="supporters.members"
          :message="supporters.message"
          @member-click="startChatCustomerSupport"
          @action-button-click="startChatCustomerSupport"
          @chat-bot-click="startChatBot"
        />
      </div>
    </div>
    <div class="online-members-container">
      <div class="online-members" id="online-members">
        <online-member
          v-for="f in followings.filter(i => i.id)"
          :key="f.id"
          :current-user="currentUser"
          :following="f"
          class="online-members--item"
          @member-click="startChatWith"
        />
      </div>
    </div>
    <div class="campaign-banner-container center-panel-image">
      <div
        v-if="Math.floor(Date.now() / 1000) <= 1594738800 && false"
        class="campaign-banner d-none d-sm-none d-md-block d-lg-block"
      >
        <a href="https://yubista.2nd-community.com">
          <img
            src="https://eys-file.s3-ap-northeast-1.amazonaws.com/gate/image/200%25+bnr_pc_sc_top.png"
            class="campaign-banner-image"
          />
        </a>
      </div>
      <div
        v-if="Math.floor(Date.now() / 1000) <= 1594738800 && false"
        class="campaign-banner d-block d-xs-block d-md-none d-lg-none d-xl-none"
      >
        <a href="https://yubista.2nd-community.com">
          <img
            src="https://eys-file.s3-ap-northeast-1.amazonaws.com/gate/image/200%25+bnr_sp_sc_top.png"
            class="campaign-banner-image-small"
          />
        </a>
      </div>
      <div
        v-if="Math.floor(Date.now() / 1000) > 1594738800 && false"
        class="campaign-banner d-none d-sm-none d-md-block d-lg-block"
      >
        <a href="https://yubista.2nd-community.com">
          <img
            src="https://eys-file.s3-ap-northeast-1.amazonaws.com/yubista/image/banner/holiday_50_off/bnr_pc_sc_top.png"
            class="campaign-banner-image"
          />
        </a>
      </div>
      <div
        v-if="Math.floor(Date.now() / 1000) > 1594738800 && false"
        class="campaign-banner d-block d-xs-block d-md-none d-lg-none d-xl-none"
      >
        <a href="https://yubista.2nd-community.com">
          <img
            src="https://eys-file.s3-ap-northeast-1.amazonaws.com/yubista/image/banner/holiday_50_off/bnr_sp_sc_top.png"
            class="campaign-banner-image-small"
          />
        </a>
      </div>
    </div>

    <div v-if="minimizedChatRooms.length" class="minimized-chat-rooms">
      <minimized-chat-room
        v-for="(room, i) in minimizedChatRooms"
        :key="i"
        :current-user="currentUser"
        :chat-room="room"
        class="minimized-chat-rooms--item"
        @click="openChatRoomModal"
        @close-button-click="removeChatRoom"
      />
    </div>

    <div class="chatbot-container">
      <chat-bot
        v-if="currentUser && showChatbot"
        :current-user="currentUser"
        :role="role"
        ref="chatBot"
      >
      </chat-bot>
    </div>

    <loading-modal v-if="loading" />
    <member-profile-modal
      v-if="memberProfileModalVisible && activeMember"
      :current-user="currentUser"
      :member="activeMember"
      class="member-profile"
      @follow-button-click="followOrUnfollow"
      @chat-start-button-click="startChat"
      @close-button-click="closeMemberProfileModal"
    />
    <community-profile-modal
      v-if="communityProfileModalVisible && selectedCommunity"
      :current-user="currentUser"
      :community="selectedCommunity"
      class="member-profile"
      @follow-button-click="followOrUnfollow"
      @chat-start-button-click="startChat"
      @close-button-click="closeCommunityProfileModal"
    />
    <chat-room
      v-if="chatRoomVisible"
      ref="chatRoom"
      :current-user="currentUser"
      :default-member="activeMember"
      :chat-room="activeChatRoom"
      :followings="followings"
      :polling-func="loadNewMessages.bind(this)"
      :video-limit="videoDurationLimit"
      :chatLoading="chatLoading"
      @message-send="sendMsg"
      @close-button-click="closeChatRoomModal"
      @reaction-send="sendRct"
    />
    <search-modal
      v-if="searchModalVisible && currentUser"
      :onlyFriends="onlyFriends"
      :isStaff="isStaff"
      :isNormal="isNormal"
      :isCreateCommunity="isCreateCommunity"
      @close-button-click="closeSearchModal"
    />
    <jingle-choice
      v-if="jingleModalVisible"
      :current-user="currentUser"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import utilsMixin from "../packs/mixins/utils";
import "swiper/css/swiper.css";
import Vue from "vue";
import ConfirmUnfollow from "../components/ConfirmUnfollow";

const ChatRoom = () => import("../components/ChatRoom");
const FollowingMember = () => import("../components/FollowingMember");
const SchoolList = () => import("../components/SchoolList");
const FriendRequest = () => import("../components/FriendRequest");
const FriendRequestItem = () => import("../components/FriendRequestItem");
const LoadingModal = () => import("../components/LoadingModal");
const MemberProfileModal = () => import("../components/MemberProfileModal");
const MinimizedChatRoom = () => import("../components/MinimizedChatRoom");
const UserRelationImage = () => import("../components/UserRelationImage");
const ChatBot = () => import("../components/ChatBot");
const OnlineMember = () => import("../components/OnlineMember");
const CustomerSupportMembers = () =>
  import("../components/CustomerSupportMembers");
const SearchModal = () => import("../components/SearchModal");
const JingleChoice = () => import("../components/JingleChoice");
const SearchBox = () => import("../components/SearchBox");
const FriendList = () => import("../components/FriendList");
const CommunityProfileModal = () =>
  import("../components/CommunityProfileModal.vue");

export default {
  name: "Home",
  components: {
    ChatRoom,
    FollowingMember,
    SchoolList,
    LoadingModal,
    MemberProfileModal,
    MinimizedChatRoom,
    UserRelationImage,
    ChatBot,
    OnlineMember,
    Swiper,
    SwiperSlide,
    CustomerSupportMembers,
    SearchModal,
    JingleChoice,
    FriendRequest,
    SearchBox,
    FriendList,
    FriendRequestItem,
    ConfirmUnfollow,
    CommunityProfileModal
  },
  mixins: [utilsMixin],
  directives: {
    swiper: directive
  },
  data() {
    return {
      memberProfileModalVisible: false,
      communityProfileModalVisible: false,
      chatRoomVisible: false,
      searchModalVisible: false,
      loading: false,
      showFriendRequest: true,
      onlyFriends: false,
      isNormal: false,
      counterKey: 0,
      isCreateCommunity: false,
      selectedCommunity: null,
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      swiperOptionSP: {
        slidesPerView: "auto",
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      chatLoading: false,
      windowWidth: window.innerWidth,
    };
  },

  created() {
    window.addEventListener("beforeunload", () => {
      this.storeMinimizedChatRoomIds();
    });
    let {roomId, teacher_id} = this.$route.query;
    if (roomId) {
      this.storeMinimizedChatRoomIds();
    }
  
    this.showLoading(this.fetchCurrentUser)
      .then(() => {
        return this.loadChatOptions();
      })
      .then(() => {
        if (this.currentUser) {
          if (teacher_id) {
          const formData = new FormData();
          teacher_id = parseInt(teacher_id);
          if (!teacher_id) return alert("incorrect data");
          formData.append('teacher_id', teacher_id);
          this.startChatWithTeacher(formData)
            .then(({data}) => {
              if (data && data.chat_room_id) {
                this.$root.$emit('open_chat_room', data.chat_room_id);
                this.$router.replace("/");
              } else {
                if (data.error)
                  alert(data.error);
                this.$router.replace("/");
              }
            });
          }
          this.getFriendRequest();
          this.fetchFollowingMembers().then(() => {
            this.restoreMinimizedChatRooms();
            this.sortFollowings();
          });
          this.fetchCustomerSupportMembers();
          this.getRole();
        } else {
          window.location.href = "/members/sign_in";
        }
      });
    document.body.style.backgroundImage = "url('/top-back.png')";
    this.setScreenWidth(this.windowWidth);
    window.addEventListener("resize", this.handleResize);
    this.getMyOwnedRooms();
  },
  mounted() {
    this.$root.$on("showFriendProfile", member => {
      this.showMemberProfile(member);
    });
    this.$root.$on("showCommunityProfile", community => {
      this.showCommunityProfile(community);
    });
    this.$root.$on("showMemberProfile", (member) => {
      this.showMemberProfile(member);
    });
    this.$root.$on("update_counts", () => {
      this.counterKey++;
    });
    this.$root.$on("open_chat_room", id => {
      this.searchModalVisible = false;
      this.startChatInCommunity(id);
    });
    this.$root.$on("open_group_room", id => {
      this.searchModalVisible = false;
      this.closeCommunityProfileModal();
      this.startChatInCommunity(id);
    });
    this.$root.$on("start-chat-with", member => {
      this.startChatWithArtMember(member);
    });
    this.$root.$on("new-message-arrived", () => {
      this.updateLastMessage();
      this.fetchFollowings();
    });
    this.$root.$on("update_community", (community, status) => {
      this.updateCommunity(community, status);
    });
    this.$root.$on("to-last-message", () => {
      this.$refs.chatRoom.scrollToLastMessage();
    });
    this.$root.$on("setLoader", (value) => {
      this.loading = value;
    });
  },
  computed: {
    ...mapState([
      "currentUser",
      "followings",
      "followingsSliced",
      "activeMember",
      "activeChatRoom",
      "minimizedChatRooms",
      "supporters",
      "role",
      "master",
      "friendRequest",
      "isCommunity",
      "activeChatRoom",
      "jingleModalVisible",
      "JingleStepChange"
    ]),
    ...mapGetters([
      "memberCount",
      "artistCount",
      "communityCount",
      "relationalMembers",
      "showChatbot",
      "videoDurationLimit",
      "isStaff",
      'checkJingleModalVisible'
    ]),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  watch: {
    jingleModalVisible(currentState){
      if(!currentState){
        return this.checkJingleModalVisible
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchCurrentUser",
      "fetchFollowingMembers",
      "fetchActiveMember",
      "followActiveMember",
      "unfollowActiveMember",
      "startChatWithActiveMember",
      "startChatWithActiveArtMember",
      "startChatWithCustomerSupport",
      "sendMessage",
      "sendReaction",
      "loadNewMessages",
      "loadChatRooms",
      "loadCommunityChat",
      "loadChatOptions",
      "fetchCustomerSupportMembers",
      "getRole",
      "getMaster",
      "deleteFriendRequest",
      "getFriendRequest",
      "getCommunities",
      "acceptFriendRequest",
      "getMember",
      "getCommunity",
      "getMyOwnedRooms",
      "startChatWithTeacher"
    ]),
    ...mapMutations([
      "setActiveMember",
      "setActiveChatRoom",
      "updateLastMessage",
      "openChatRoom",
      "closeChatRoom",
      "removeChatRoom",
      "setFollowingMembers",
      "setScreenWidth"
    ]),

    showLoading(fn) {
      this.loading = true;
      const promise = fn();
      if (promise) {
        promise.finally(() => {
          this.$nextTick(() => {
            this.loading = false;
          });
        });
      } else {
        this.$nextTick(() => {
          this.loading = false;
        });
      }
      return promise;
    },

    showMemberProfile(member) {
      this.setActiveMember(member);
      this.memberProfileModalVisible = true;
      this.showLoading(this.fetchActiveMember).then(() => {
      });
    },

    showCommunityProfile(community) {
      this.loading = true;
      this.getCommunity(community.id).then(result => {
        this.selectedCommunity = result.data;
        this.communityProfileModalVisible = true;
        this.loading = false;
      });
    },

    showSearchModal(condition, isCreateCommunity = false, isNormal = false) {
      this.inject();
      this.onlyFriends = condition;
      this.isCreateCommunity = isCreateCommunity;
      this.isNormal = isNormal;
      if (this.master) {
        this.searchModalVisible = true;
      } else {
        this.showLoading(this.getMaster).then(() => {
          this.searchModalVisible = true;
        });
      }
    },

    followOrUnfollow(v) {
      if (v.following) {
        var ComponentClass = Vue.extend(ConfirmUnfollow);
        var confirmFollowOrUnfollow = new ComponentClass({
          propsData: {}
        });
        confirmFollowOrUnfollow.$on("acceptUnfollowConfirm", member => {
          this.unfollowActiveMember();
        });
        confirmFollowOrUnfollow.$mount();
        window.document.body.appendChild(confirmFollowOrUnfollow.$el);
      } else {
        this.followActiveMember();
      }
    },

    startChat() {
      this.chatRoomVisible = true;
      this.closeMemberProfileModal();
      this.startChatWithActiveMember().then(() => {
        this.updateLastMessage();
        this.fetchFollowings();
      });
    },

    startChatWithArtMember(member) {
      this.chatRoomVisible = true;
      this.setActiveMember(member);
      this.startChatWithActiveArtMember();
    },

    startChatWith(member) {
      this.setActiveMember(member);
      this.startChat();
    },

    startChatCustomerSupport() {
      this.chatRoomVisible = true;
      this.closeMemberProfileModal();
      this.startChatWithCustomerSupport();
    },

    startChatInCommunity(id) {
      this.chatRoomVisible = true;
      this.loadCommunityChat(id).then(() => {
        this.updateLastMessage();
        this.fetchFollowings();
      });
    },

    sendMsg(message, mentions, file, replyToMessageId) {
      const formData = new FormData();
      if (!message) {
        message = "";
      }
      if (file && file.length > 1) {
        file.shift(); // 初期値削除
        file.forEach(f => formData.append("file[]", f));
        this.chatLoading = true;
      }
      if (mentions && mentions.length > 0) {
        mentions.forEach(m => formData.append("mentions[]", m));
      }
      formData.append("message", message);
      formData.append("timestamp", new Date().toISOString());
      if (replyToMessageId) {
        formData.append("reply_to", replyToMessageId);
      }

      this.sendMessage(formData).then(() => {
        this.updateLastMessage();
        this.$refs.chatRoom.scrollToLastMessage();
        this.fetchFollowings();
        if (this.chatLoading) {
          this.chatLoading = false;
        }
      });
    },

    sendRct(message, reaction) {
      this.sendReaction({ msgId: message.id, reaction })
        .then(() => {})
        .catch(error => console.error(error));
    },

    closeMemberProfileModal() {
      this.memberProfileModalVisible = false;
    },

    closeCommunityProfileModal() {
      this.selectedCommunity = null;
      this.communityProfileModalVisible = false;
    },

    closeChatRoomModal() {
      this.chatRoomVisible = false;
      this.closeChatRoom();
    },

    closeSearchModal() {
      this.searchModalVisible = false;
    },

    // closeJingleModal() {
    //   this.jingleModalVisible = false;
    // },

    openChatRoomModal(id) {
      this.openChatRoom(id);
      this.chatRoomVisible = true;
    },

    storeMinimizedChatRoomIds() {
      if (this.activeChatRoom) {
        this.closeChatRoom();
      }
      let minimizedChatRoomIds = this.minimizedChatRooms.map(r => r.id);
      let { roomId } = this.$route.query;
      if (roomId) {
        const roomIdParsed = parseInt(roomId, 10);
        if (minimizedChatRoomIds.length === 0) {
          minimizedChatRoomIds.push(roomIdParsed);
        }
        if (
          minimizedChatRoomIds.length > 0 &&
          !minimizedChatRoomIds.includes(roomIdParsed)
        ) {
          minimizedChatRoomIds.push(roomIdParsed);
        }
      }
      if (minimizedChatRoomIds.length) {
        localStorage.setItem(
          "minimizedChatRoomIds",
          JSON.stringify(minimizedChatRoomIds)
        );
      }
    },

    restoreMinimizedChatRooms() {
      let storedChatRoomIds = localStorage.getItem("minimizedChatRoomIds");
      if (storedChatRoomIds) {
        const ids = JSON.parse(storedChatRoomIds);
        if (ids) {
          this.loadChatRooms(ids).then(response => {
            if (response.data.length > 0) {
              localStorage.removeItem("minimizedChatRoomIds");
              let { roomId } = this.$route.query;
              const roomIdParsed = parseInt(roomId, 10);
              if (roomId) {
                this.$router.replace("/");
                this.openChatRoomModal(roomIdParsed);
                this.chatRoomVisible = true;
              }
            } else {
              localStorage.removeItem("minimizedChatRoomIds");
              storedChatRoomIds = [];
              this.$router.replace("/");
            }
          });
        }
      }
    },

    startChatBot() {
      this.$refs.chatBot.$refs.greeterChatBubble
        .querySelector(".bot-avatar")
        .click();
    },

    openFriendRequests() {
      this.showFriendRequest = !this.showFriendRequest;
    },
    deleteMemberFriendRequest(member) {
      this.deleteFriendRequest(member);
    },
    acceptMemberFriendRequest(member) {
      this.acceptFriendRequest(member);
    },
    sortFollowings() {
      this.followings.sort((a, b) => {
        if (a.lastMessage && b.lastMessage) {
          return (
            new Date(b.lastMessage.timestamp) -
            new Date(a.lastMessage.timestamp)
          );
        } else if (a.lastMessage && !b.lastMessage) {
          return -1;
        } else if (!a.lastMessage && b.lastMessage) {
          return 1;
        } else if (!a.lastMessage && !b.lastMessage) {
          return 0;
        }
      });
      this.setFollowingMembers(this.followings);
    },
    fetchFollowings() {
      this.sortFollowings();
    },
    updateCommunity(community, status) {
      let index = -1;
      let parentIndex = 0;
      for (let j = 0; j < this.followingsSliced.length; j++) {
        for (let i = 0; i < this.followingsSliced[j].length; i++) {
          if (
            this.followingsSliced[j][i].community &&
            this.followingsSliced[j][i].community.id === community.id
          ) {
            index = i;
            parentIndex = j;
            break;
          }
        }
        if (index > -1) {
          break;
        }
      }

      if (index > -1) {
        if (status === "updated") {
          this.followingsSliced[parentIndex][index].community.name =
            community.name;
          this.followingsSliced[parentIndex][index].community.image =
            community.image;
        } else if (status === "removed") {
          this.followingsSliced[parentIndex].splice(index, 1);
        }
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.setScreenWidth(this.windowWidth);
    }
  }
};
</script>

<style scoped>
#app {
  position: relative;
  min-height: 1080px;
}

.top {
  width: 100%;
  padding-top: 14px;
  position: relative;
  background-color: transparent;
  margin: 0 auto;
}

.hello {
  height: 108px;
  width: 100%;
  margin: 24px auto 0 auto;
  position: relative;
}

.hello img {
  margin-left: 5%;
  height: 108px;
  width: 90%;
}

.swiper-pagination-bullet-active {
  background-image: linear-gradient(to right, #bbbbff 0%, #66bbff 100%);
}

.minimized-chat-rooms {
  position: absolute;
  top: 10px;
  right: 0;
  width: 240px;
  z-index: 10;
}

.minimized-chat-rooms--item {
  margin-top: 10px;
}

.following-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px auto 20px;
}

.following-members--item {
  box-sizing: content-box;
  padding: 8px 15px;
}

.online-members-container {
  overflow: auto;
}

.online-members {
  display: none;
  margin: 10px auto;
  background-color: whitesmoke;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
}

.online-members--item {
  margin: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 20%;
  right: 1%;
}

.center-info {
  margin-top: 20px;
  position: relative;
  padding-bottom: 20px;
}

.center-info--logo {
  width: 189px;
  height: 31px;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.center-info--members {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-align: center;
  color: #222222;
}
.center-info--members-count {
  display: inherit;
}

.center-info--member-count {
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: normal;
  color: #7799dd;
}

.center-panel-image {
  width: 768px;
  height: 150px;
  display: block;
  margin: 10px auto 0 auto;
}

.customer-support-container {
  position: fixed;
  right: -45px;
  bottom: 0px;
  z-index: 90;
  transform: scale(0.75);
}

.campaign-banner-container {
  margin-top: 70px;
  padding-left: 2rem;
  padding-right: 2rem;
  @include media-breakpoint-up(md) {
    margin-top: 13.5rem;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 4rem;
  }
}
.campaign-banner {
  text-align: center;
  align-items: center;
}
.campaign-banner-image-small {
  width: 100%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.26);
  border-radius: 15px;
}
.campaign-banner-image {
  width: 100%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.26);
  border-radius: 15px;
}
.school-list {
  max-height: 195px;
}
.friend-request {
  position: absolute;
  z-index: 1;
  top: 3%;
}
.friend-request-item {
  position: absolute;
  z-index: 101;
  top: 3%;
}
.chatbot-container {
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  z-index: 99;
}
.top--search-box {
  position: absolute;
  top: 29px;
  left: 50%;
  margin-left: -150px;
}
.center-info--my-list {
  position: relative;
  display: block;
  margin: 20px auto 0px;
  width: fit-content;
}

@media screen and (max-width: 460px) {
  #app {
    min-height: 560px;
  }
  .hello {
    width: 100%;
    padding-top: 0px;
    margin: 0;
  }
  .hello img {
    width: 96%;
    margin-right: 8px;
    margin-left: 8px;
  }
  .school-list {
    margin-top: -40px;
  }
  .scrollable-container {
    overflow: auto;
    padding-bottom: 0px;
    margin-top: -40px;
  }
  .top {
    padding-bottom: 0;
  }
  .center-info {
    bottom: 0;
    margin-top: 12px;
    padding-bottom: 8px;
  }
  .center-info--my-list {
    margin-top: 12px;
  }
  .container-members {
    display: inline-flex;
  }
  .following-members {
    flex-shrink: 0;
    flex-grow: 0;
  }
  .campaign-banner-container {
    display: none;
  }
  .customer-support-container {
    right: -50px;
    bottom: -18px;
    transform: scale(0.7);
  }
  .chatbot-container {
    left: 5px;
    bottom: 5px;
  }
  .center-info--members {
    display: block;
  }
  .center-info--members-count {
    display: flex;
    justify-content: center;
    align-content: center;
  }
}

@media screen and (max-width: 767px) {
  .swiper-slide.single-swiper {
    width: 100%;
  }

  .swiper-slide.double-swiper {
    width: 50%;
  }

  .slider-sp {
    display: block;
  }

  .slider-pc {
    display: none;
  }

  .online-members {
    position: relative !important;
    display: flex;
    margin: 0px;
  }
  .center-panel-image {
    width: 500px;
  }
  .campaign-banner-container {
    width: 100%;
    margin-top: 50px;
  }
  .hello {
    width: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .slider-sp {
    display: none;
  }

  .slider-pc {
    display: block;
  }
}
@media screen and (max-width: 576px) {
  .center-panel-image {
    width: 350px;
    height: 120px;
  }
}
@media screen and (max-width: 575px) {
  .friend-request-item {
    right: calc(50% - 160px);
  }
}
@media screen and (max-width: 350px) {
  .center-panel-image {
    width: 320px;
    height: 120px;
  }
  .customer-support-container {
    right: -83px;
    transform: scale(0.5);
  }
}

@media screen and (max-height: 780px) {
  .center-info {
    position: relative !important;
  }
}

@media screen and (max-width: 1024px) {
  .minimized-chat-rooms {
    display: none;
  }
}

@media screen and (max-width: 1462px) {
  .hello {
    width: 42%;
    margin: auto;
  }
}
</style>

<style>
body {
  font-family: HiraginoSans, Meiryo, Arial, sans-serif;
  font-size: 16px;
}
</style>
