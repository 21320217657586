/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.
import Vue from 'vue'
import VueRouter from 'vue-router'
import TurbolinksAdapter from "vue-turbolinks"
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSmile as farSmile } from "@fortawesome/free-regular-svg-icons";
import { faReply, faSmile, faSurprise, faFrown, faAngry, faThumbsUp, faThumbsDown, faHeart, faHeartBroken, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VTooltip from 'v-tooltip';

import Home from '../pages/Home.vue'
import ChatRoom from '../components/ChatRoom.vue'

library.add(farSmile, faSmile, faSurprise, faFrown, faAngry, faReply, faThumbsUp, faThumbsDown, faHeart, faHeartBroken, faTimes);
Vue.component('fa', FontAwesomeIcon);

Vue.use(require('vue-moment'));
Vue.use(VueRouter)
Vue.use(TurbolinksAdapter)
Vue.use(VTooltip)
Vue.config.ignoredElements = ['greeter-chat-window', 'greeter-chat-bubble']
Vue.config.productionTip = false;

import Page from '../pages/Home.vue'
import { store } from '../store/home'

const routes = [
  { path: '/home', component: Home },
  { path: '/chatroom', component: ChatRoom }
];
const router = new VueRouter({
  routes,
  mode: 'history'
});

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById('home')
  if (el) {
    new Vue({
      el,
      store,
      router,
      render: h => h(Page)
    })
  }
})

// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../App.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../pages/Home.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#home',
//     data: () => {
//       return {}
//     },
//     components: { App }
//   })
// })
