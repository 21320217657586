export function fixBackground() {
  const positionY = window.scrollY;
  document.body.style.top = `-${positionY}px`;
  document.body.classList.add('fixed');
  return positionY;
}

export function activateBackgroundScroll(positionY) {
  document.body.classList.remove('fixed');
  document.body.style.top = '0';
  window.scrollTo(0, positionY);
}
